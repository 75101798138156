
window.vzwgnav={state:{}}

var currentPageName = location.pathname.replace('/','')
if(currentPageName.substr(-1) == '/'){
	currentPageName = currentPageName.substr(0,location.pathname.length-2)
}
var cradleReport = {"currentPageName":currentPageName}

if(/gnav2test/.test(window.location.href)){
	var gnavTestParam = true
	loadGnav2('test param')
}else if(!gnav.getCookie("loggedIn") ){
	if(!gnav.getCookie("gnavThrottleList")){
		callGlobalHeader('gnavThrottleList cookie undefined')	
	}else if(gnav.getCookie("gnavThrottleList") !== 'none'){
		loadCradleTest('gnavThrottleList cookie != none')
	}
}

function callGlobalHeader(origin){
	gnav.makeAjaxCall(gnav.cnst.HEADER_JSON_URL_PROSPECT, "GET").then(function (userData) {		  	
		cradleReport.callGlobalHeader = 'from '+ origin
		if((userData.cradleConfigurations && userData.cradleConfigurations.cradleKillSwitch == 'false')){ // (userData.cradleConfigurations && userData.cradleConfigurations.cradleKillSwitch == 'false' && gnav.getCookie("loggedIn") && !gnav.getCookie("LoggedInAllocation")) || 
			var throttleList = userData.cradleConfigurations.throttleList || '|basic-phones|'// NEED TO GET throttleList FROM GLOBAL HEADER JSON
							
			if(throttleList.indexOf(currentPageName)>-1){	
				//callCradleApi(userData.cradleConfigurations)
			}			
			
			
			callCradleApi(userData.cradleConfigurations) // moved out of previous condition for phase 2
			throttleList = 'all' // changing throttleList to 'all' for phase 2
			
			
			throttleList = throttleList.replace('-','+')
			var cookieExp = userData.cradleConfigurations.cookieExp || 1
			gnav.createCookie('gnavThrottleList',throttleList,cookieExp)
		}else{
			gnav.createCookie('gnavThrottleList','none')
		}		
	});
}





function callCradleApi(config){
	cradleReport.callCradleApi='cradle kill switch = '+config.cradleKillSwitch
	
	var throttlingApiUrl =  config.cradleApiUrl,
	throttlingApiObjParam = {
		postData:JSON.stringify({
	    "properties": [
	        {
	            "name": "applicationId",
	            "value": "gnav-vzw"
	        },
	        {
	            "name": "pageName",
	            "value": "gnav-vzw"
	        },
	        {
	            "name": "loggedIn",
	            "value": gnav.getCookie("loggedIn") ? "true" : "false"
	        }
	    ]
		}),
	    "headers":[
		    	{"name":"Content-Type","value": "application/json"},
	    	]
	}
	
	config['channelId'] && throttlingApiObjParam.headers.push({"name":"channelId","value": config['channelId']})
	config['x-apikey'] && throttlingApiObjParam.headers.push({"name":"x-apikey","value": config['x-apikey']})
	config['x-nonprod-env'] && throttlingApiObjParam.headers.push({"name":"x-nonprod-env","value": config['x-nonprod-env']})
	
	
	// TEMP FOR LOCAL TESTING

	if(/localhost/.test(location.href)){
		gnav.meth = 'GET'
		throttlingApiUrl = '/content/cradleApi.json'
	}else{
		gnav.meth = 'POST'
	}
	
	gnav.makeAjaxCall(throttlingApiUrl, gnav.meth, throttlingApiObjParam).then(function (resp) {  
		   
    var cookieName = gnav.getCookie("loggedIn") ? "LoggedInAllocation" : "LoggedOutAllocation"
    
    if(resp && resp.throttleList){
    	var testVersion = resp.throttleList.replace('-','+')
    	var cookieExp = resp.cookieExp || 1
    	
      gnav.createCookie(cookieName,testVersion,cookieExp)
      
      loadCradleTest('callCradleApi') 
    }

  }).catch(function () {
    cradleReport.callCradleApi_ERROR=true
  })
}




function loadCradleTest(origin){
	cradleReport.loadCradleTest='from '+origin
	var testVersion = gnav.getCookie("loggedIn") ? gnav.getCookie("LoggedInAllocation") : gnav.getCookie("LoggedOutAllocation")
	var throttleList = gnav.getCookie("gnavThrottleList")
	var taggingTerm
	
	
	// START - Added code for phase 2
	if(!testVersion || gnavOptions.version == 'privacy' || /gnav2test/.test(window.location.href) && location.host.indexOf('es') !== 0){
		testVersion = 'gnav_1_0'
	} 	
	updateDLforThrottling(testVersion)
	// END - Added code for phase 2
	
	/* this code commented for phase 2
	
	
	if(false && currentPageName && throttleList && typeof throttleList === 'string'){
		throttleList = throttleList.replace('+','-')		
		
		if(!testVersion && throttleList.indexOf(currentPageName) > -1){
			callGlobalHeader('loadCradleTest')
			return
		}
		
		if(throttleList.indexOf(currentPageName) > -1 && testVersion && typeof testVersion === 'string'){		
				testVersion = testVersion.replace('+','-').toLowerCase()
				taggingTerm = testVersion.indexOf('bau') === -1 ? 'gnav_2_0' : 'gnav_1_0'
				updateDLforThrottling(taggingTerm)
		}
		if(taggingTerm == 'gnav_2_0' && gnavOptions.version !== 'privacy' && !/gnav2test/.test(window.location.href) && location.host.indexOf('es') !== 0){  	
				loadGnav2('loadCradleTest')
	  }	
	}
	*/
}


	
	
	
function loadGnav2(origin){
	cradleReport.loadGnav2='from '+origin

	var newNavURL = (gnav.cnst.runningLowerEnv)
			? gnav.cnst.APP_DOMAIN + '/content/wcms/one-digital/gnav/header/vcg/wireless/master.internal.html'
			: '/one-digital/gnav/header/vcg/wireless/master.internal.html'
	var newJSURL = (gnav.cnst.runningLowerEnv)
			? gnav.cnst.APP_DOMAIN + '/etc/designs/vzwcom/gnav20.js'
			: '/etc/designs/vzwcom/gnav20.js'
		
	//newNavURL = '/content/gnav3.html'
	gnavOptions.hideGnav = true

	gnav.makeAjaxCall(newNavURL, "GET").then(function (resp) {     
    cradleReport.loadGnav2Success = 'ng-header index = '+resp.indexOf('ng-header')
    if(resp.indexOf('ng-header')>-1){
    	gnav.newNav = document.createElement('div')
      gnav.newNav.id = 'gnav2'
      gnav.newNav.role = 'banner'
      gnav.newNav.innerHTML = resp
      document.body.insertBefore(gnav.newNav, document.body.firstElementChild);
      
			gnav.script2 = document.createElement('script')
      gnav.script2.type = 'text/javascript';
      gnav.script2.src = newJSURL
      document.body.insertBefore(gnav.script2, document.body.firstElementChild);
      
      var origGnav = document. getElementById('vzw-gn');
      var origGfoot = document. getElementById('vzw-gf')
      origGnav. parentNode. removeChild(origGnav);
      //origGfoot. parentNode. removeChild(origGfoot);

  	}else{	
  		cradleReport.loadGnav2ERROR = 'bad response'
  		gnavOptions.hideGnav = false
  		loadGnav()
  	}			
  }).catch(function () {
    cradleReport.loadGnav2ERROR = 'catch function'
    gnavOptions.hideGnav = false
    loadGnav()
  })
}
	
	
	





function updateDLforThrottling(testVersion){
	cradleReport.updateDLforThrottling=testVersion
	console.log(cradleReport)
	window.throttlingThreshold = 0
  window.throttlingTestVersion = testVersion
  window.throttlingInterval = setInterval(function(){

  	if(window.throttlingThreshold<100){
  		if(!window.vzwDL){
  			window.throttlingThreshold ++
  			return false;
  		}
  		if(window.vzwDL.page.testVersion){
  			window.vzwDL.page.testVersion += ',' + window.throttlingTestVersion
  		}else{
  			window.vzwDL.page.testVersion = window.throttlingTestVersion
  		}
  		
  		if (window.vzwAnalytics) {
				var eventName = 'variableUpdater',
				params ={ customLinkName: 'gnav impressions', eVar8: vzwDL.page.flowName, prop53: vzwDL.page.testVersion };
				vzwAnalytics.events.track(eventName, params);
			}
  		
  		clearInterval(window.throttlingInterval)

  	}else{
  		clearInterval(window.throttlingInterval)
  	}
  },5)
}




