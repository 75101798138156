/*

window.tTdB=1;

var myNewSpan = document.createElement('span')
myNewSpan.innerHTML = '<span class="global-a11y-tooltip" style="font-size:.6em">You can style this element to suite your needs but the color and size should be appropriate by default.</span>'
document.querySelector('#joe').appendChild(myNewSpan)

var myNewSpan = document.createElement('span')
myNewSpan.innerHTML = '<span class="global-a11y-tooltip" style="color:#FFF;margin:0 24px 0 0">here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.</span>'
document.querySelector('#ribbon .clearfix').appendChild(myNewSpan)

window.tTdB=1;
var myNewSpan = document.createElement('span')
myNewSpan.innerHTML = '<span class="global-a11y-tooltip" >here is some content for the tooltip to show when users click on the tooltip icon. <a href="http://cnn.com">Click me!</a> here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.  here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.  here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.  here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.  here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.  here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.  here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon. </span>'
document.getElementById('joe').appendChild(myNewSpan)


var myNewSpan = document.getElementById('joe')
myNewSpan.innerHTML = myNewSpan.innerHTML + '<span><span class="global-a11y-tooltip" icon-color="white">here is some content for the tooltip to show when users click on the tooltip icon. here is some content for the tooltip to show when users click on the tooltip icon.</span></span>'

*/
window.tTdB=0;
window.addEventListener('load', function() {
/********************************************
MutationObserver reference
GOOD - https://davidwalsh.name/mutationobserver-api
GOOD - https://codeburst.io/observe-changes-in-dom-using-mutationobserver-9c2705222751
*************************************************/
    /***************************************
    This observer routine will monitor DOM changes and initialize 
    any tooltips that get added dynamically to the page
    ****************************************/
    // target element that we will observe
		var target = document.body,
				w = window;
		
		// config object
		var config = {
			 /*
		  attributes: true,
		  attributeOldValue: true,
		 
		  characterData: true,
		  characterDataOldValue: true,
		 */
		  childList: true,
		  subtree: true   
		};
		

		function subscriber(mutations) {
			w.tTdB && console.log('-mutations, count =',mutations.length);
			
			var tooltipFound = false,
					mutation,
					addedNodes,
					ttips,
					i,ii;
			
		  for(i=0; i< mutations.length; i++) {
		  	
		  	w.tTdB && console.log('--mutation',i+1);
		  	
		  	addedNodes = mutations[i].addedNodes
		  	ttips = null
		  	
		    for(ii=0; ii< addedNodes.length; ii++){
			    
			    w.tTdB && console.log('---',addedNodes[ii]);
			    
			    ttips = addedNodes[ii].getElementsByClassName && addedNodes[ii].getElementsByClassName('global-a11y-tooltip')
			    
					if(ttips && ttips.length){
						tooltipInit();
						w.tTdB && console.log('----ttips',ttips);	
					}
			  }
		  }
		}
		
		// instantiating observer
		var observer = new MutationObserver(subscriber);

	/****************************************************/	
    
    var tooltipBubble = null,
        userIsTouching = false,
        openTrigger,
        returnFocus = false,
        tooltipLabel = 'more information tooltip',
        closeButtonHTML = '<br /><button class="a11y-tooltip-close">&times;</button>';
        
    function openClick(e){
    	//console.log('openClick',e.target,openTrigger)
    	if(document.getElementById('global-a11y-tooltip-bubble') && e.target == openTrigger) {
      	tooltipClose(e);
      } else{
      	tooltipOpen(e);
      }  
    }
    function openMouseenter(e){
    	//console.log('openMouseenter',e.type)
    	if(!userIsTouching){// touch screens pass mouseenter event after touchstart, we don't want this, we want click
       	tooltipOpen(e)
      }
    }
    function openMouseleave(e){
    	//console.log('openMouseleave',e.type)
    	if(!userIsTouching){// touch screens pass mouseenter event after touchstart, we don't want this, we want click
       	setTimeout(function(){
       		window.mouseNotInBubble && tooltipClose()
       	},50)
      }
    }
    function openTouchstart(e){
    	//console.log('openTouchstart',e.type)
    	userIsTouching = true;
    }
    function closeClick(e){
    	//console.log('closeClick',e.type)
    	tooltipClose(e);
    }
    function closeFocus(e){
    	//console.log('closeFocus',e.type)
    	var closeButton = document.querySelector('#global-a11y-tooltip-bubble .a11y-tooltip-close')
    	if(closeButton){
    		closeButton.className += ' pseudo-focus'
    	}
    }
    function closeBlur(e){
    	//console.log('closeBlur',e.type)
    	tooltipClose(e)// removing close from blur may be necessary for screen reader if there are focusable elements in tooltip content
    	//document.querySelector('#global-a11y-tooltip-bubble .a11y-tooltip-close').className -= ' pseudo-focus'
    }
    function closeKeyup(e){
    	//console.log('closeKeyup',e.which,e.shiftKey)
    	if (e.keyCode != 9 && e.keyCode == 27 || (e.keyCode == 9 && e.shiftKey)) {
        if (document.getElementById('global-a11y-tooltip-bubble')) {
            tooltipClose(e);
        }
      }
    }
    function bubbleEnter(e){
    	window.mouseNotInBubble = false;
    }
    function bubbleLeave(e){
    	window.mouseNotInBubble = true;
    	tooltipClose(e)
    }
    
    function tooltipInit() { 
    	w.tTdB && console.log('tooltipInit')
    	
    	// dis-engage observer while we modify the DOM
      observer && observer.disconnect();
      
      // Find all the tooltips and add the wrapper, target link, and event handlers
    	//document.querySelectorAll('.global-a11y-tooltip').forEach((element,index)=>{
    		
    	var rawTooltips = 	document.getElementsByClassName('global-a11y-tooltip'),
    			element,
    			i;
    	
    	for(i=0; i<rawTooltips.length; i++){
    		element = rawTooltips[i]
    		
      	if(element.parentNode.className !== 'a11y-tooltip-wrapper'){
      		
      		w.tTdB && console.log('-tooltip',i+1)
      		
      		element.setAttribute('aria-hidden',true)
					element.innerHTML += closeButtonHTML
					
					var closeButton = element.querySelector('button.a11y-tooltip-close')
      		var parent = element.parentNode
					var wrapper = document.createElement('span')
					var trigger = document.createElement('a')
					var ariaLabel = element.getAttribute('icon-aria-label') || tooltipLabel
					
					
					wrapper.className = 'a11y-tooltip-wrapper'
					wrapper.style.cssText  = element.style.cssText 
					element.style.cssText = ''
					
					/* NOT CURRENTLY IN USE
					var computedStyle = window.getComputedStyle(parent, null);
					if(element.getAttribute('icon-color')){
						trigger.className += ' color-' + element.getAttribute('icon-color')
					}else if(parseInt(computedStyle.color.substr(4)) > 200){
						trigger.className += ' color-white'
					}
					*/
					
					element.getAttribute('icon-size') && (parent.style.fontSize = element.getAttribute('icon-size'))
					
					trigger.href = '#'
					// choose one or the other method for announcing expanded
					//trigger.setAttribute('aria-label', ariaLabel +' collapsed')
					trigger.setAttribute('aria-label', ariaLabel)
					trigger.setAttribute('aria-expanded', 'false');
					
					
					trigger.addEventListener("click", openClick)
					trigger.addEventListener("mouseenter", openMouseenter)
					trigger.addEventListener("mouseleave", openMouseleave)
					trigger.addEventListener("touchstart", openTouchstart)
					trigger.addEventListener("keyup", closeKeyup)
					
					closeButton.addEventListener("click", closeClick)
					closeButton.addEventListener("focus", closeFocus)
					closeButton.addEventListener("blur", closeBlur)
					closeButton.addEventListener("keyup", closeKeyup)
					closeButton.setAttribute("aria-label", "Close information tooltip")
					closeButton.setAttribute("tabindex", "-1")
					
					// replace the element with the wrapper
					parent.replaceChild(wrapper, element);
					// add the trigger to the wrapper then add the original element back into the wrapper
					wrapper.appendChild(trigger);
					wrapper.appendChild(element);
      		
      	}
      }//)
      
      // re-engage observer
      observer.observe(target, config);
    }

    function tooltipBubbleInit(tooltipBubble) {
      w.tTdB && console.log('-tooltipBubbleInit')    
      
      
        
      var excessive_height = false,
      		tooltipArrowPosition,
      		addClass = '',
      		maxTries = 0,
      		pos_left = 0,
      		pos_top = 0,
					scroll_left = window.scrollX || window.pageXOffset || document.body.scrollLeft + (document.documentElement && document.documentElement.scrollLeft || 0),
      		scroll_top = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0),
      		target_left = parseInt(openTrigger.getBoundingClientRect().left),
      		target_top = parseInt(openTrigger.getBoundingClientRect().top),
      		viewPortWidth = document.documentElement.clientWidth,
      		viewPortHeight = document.documentElement.clientHeight,
      		margin = viewPortWidth < 768 ? 15 : 20,
      		ariaLabel = openTrigger.nextElementSibling.getAttribute('icon-aria-label') || tooltipLabel,
      		iconSize = parseInt(window.getComputedStyle(openTrigger, null).getPropertyValue("font-size"));

      // position box ABOVE trigger based on target position and bubble height
      if(target_top >= viewPortHeight/2){
      	// If tooltip bubble is too tall, try to widen the bubble until it fits without exceeding viewport width
      	maxTries=0
      	while(maxTries < 99 && tooltipBubble.offsetHeight > target_top - 20 && tooltipBubble.offsetWidth < viewPortWidth - 30){
        	tooltipBubble.style.width = tooltipBubble.offsetWidth + 10 + 'px';       	
        	maxTries++
        }

        if(tooltipBubble.offsetHeight > target_top - 20){
        	excessive_height = true
        }else{
        	pos_top += (scroll_top + target_top - tooltipBubble.offsetHeight - 12);
        }  
      }
      
      // position box BELOW trigger based on target position and bubble height
      if(target_top < viewPortHeight/2 || excessive_height){
      	// If tooltipBubble is too tall, try to widen the bubble until it fits without exceeding viewport width
      	maxTries=0
      	while(maxTries < 99 && tooltipBubble.offsetHeight > viewPortHeight - target_top - 60 && tooltipBubble.offsetWidth < viewPortWidth - 30){
        	tooltipBubble.style.width = tooltipBubble.offsetWidth + 10 + 'px';
        	//console.log('offsetHeight',tooltipBubble.offsetHeight,tooltipBubble.style.width)
        	maxTries++
        }
      	pos_top += (scroll_top + target_top + iconSize + 15);
      	addClass = 'arrow-top';
      }
      
      // position toward left or right of trigger based on screen position
      pos_left = target_left + 8 - tooltipBubble.offsetWidth/2 ;
    	if(tooltipBubble.offsetWidth > viewPortWidth - 40) {
        pos_left = (viewPortWidth - tooltipBubble.offsetWidth)/2;
        //console.log('centered')
      }else if(pos_left < 20){
      	pos_left = 20;
      	//console.log('move right')
      }else if(pos_left + tooltipBubble.offsetWidth > viewPortWidth - 20){
      	pos_left = viewPortWidth - 20 - tooltipBubble.offsetWidth;
      	//console.log('move left')
      }
		
			tooltipBubble.style.left = pos_left+'px';
			tooltipBubble.style.top = pos_top+'px';
			tooltipBubble.className += addClass;	    
     	tooltipArrowPosition = parseInt(openTrigger.getBoundingClientRect().left) - tooltipBubble.offsetLeft + (iconSize/2 - 7); 
     	
      /*     
      if(tooltipArrowPosition <= 2){
      	tooltipArrowPosition = openTrigger.offsetWidth/2 - 6;
      }
     */

      tooltipBubble.querySelector('.tooltip-arrow').style.left = tooltipArrowPosition+'px';

      // choose one or the other method for announcing expanded
      //openTrigger.setAttribute('aria-label', ariaLabel+' expanded');
      openTrigger.setAttribute('aria-expanded', 'true');
  
    };
    
    function tooltipOpen(e) {
        e.stopPropagation()
        e.preventDefault()
        
        w.tTdB && console.log('tooltipOpen',e.type)
        
        //disengage observer while we add tooltip bubble to the DOM
				observer && observer.disconnect();
				
				// in case we already have a tooltipBubble open on another tooltip trigger
        removeTooltipBubble()
        
        openTrigger = e.target
        
        if (openTrigger.nextElementSibling.childNodes.length < 2) {
            return false // means there is no tooltip content to display in the bubble
        }
        
        // we need to return focus to the trigger element upon close
        returnFocus = true

        var tipContent = openTrigger.nextElementSibling.innerHTML

        openTrigger.nextElementSibling.setAttribute('aria-hidden',false)
        openTrigger.nextElementSibling.querySelector('.a11y-tooltip-close') && openTrigger.nextElementSibling.querySelector('.a11y-tooltip-close').setAttribute('tabindex','0')
        
        tooltipBubble = document.createElement('div')
        tooltipBubble.id = 'global-a11y-tooltip-bubble'
        tooltipBubble.innerHTML = '<span class="tooltip-arrow"></span><div class="tooltip-content">'+ tipContent + '</div>'
				if(e.type !== 'click'){
					tooltipBubble.querySelector('.a11y-tooltip-close').style.display = 'none'
					returnFocus = false
				}else{
					tooltipBubble.querySelector('.a11y-tooltip-close').addEventListener("click", closeClick)
				}
				tooltipBubble.addEventListener("mouseenter", bubbleEnter)
				tooltipBubble.addEventListener("mouseleave", bubbleLeave)
	
				document.body.appendChild(tooltipBubble)			
				tooltipBubbleInit(tooltipBubble)
    }
    
    function tooltipClose(e) {
       	w.tTdB && console.log('--tooltipClose',e && e.type)
        e && e.stopPropagation()
        e && e.preventDefault()

        var ariaLabel = openTrigger.nextElementSibling.getAttribute('icon-aria-label') || tooltipLabel     
        
        // choose one or the other method for announcing expanded
        //openTrigger.setAttribute('aria-label', ariaLabel+' collapsed')
        openTrigger.setAttribute('aria-expanded', 'false');
        
        openTrigger.nextElementSibling.setAttribute('aria-hidden',true)
        openTrigger.nextElementSibling.querySelector('.a11y-tooltip-close') && openTrigger.nextElementSibling.querySelector('.a11y-tooltip-close').setAttribute('tabindex','-1')
        
        returnFocus && openTrigger.focus()
        
        removeTooltipBubble()
        
        // re-engage observer
        observer.observe(target, config);

    };
    
    function removeTooltipBubble(){
    	if(document.getElementById('global-a11y-tooltip-bubble')){   		
    		tooltipBubble.querySelector('.a11y-tooltip-close') && tooltipBubble.querySelector('.a11y-tooltip-close').removeEventListener("click", closeClick)
       	// document.body.removeChild(tooltipBubble)
       	document.body.removeChild(document.getElementById('global-a11y-tooltip-bubble'))
    	}
    }
    
    tooltipInit()
    // observing target
		observer.observe(target, config);
		
		document.body.addEventListener('click',()=>{removeTooltipBubble()})

})