var vzgn = vzgn || {};
// https://preprodbillpaysvc.verizonwireless.com/vzwsvc/asc/GlobalNavServicesInfo.action?_=1521042273337

var gnlin_amIDCookieName = "amID";
var gnlin_oneBillCompCookieName = "OC";
var gnlin_gnServScrCallEnabled = "N";
var gnlin_oneVZ = "oneVerizon";
var gnlin_alltelCookieName = "alltelDevice";

/** setCompOBCookie Should be a global function so that the MYV team can 
 *  call setCompOBCookie method from their end 
 * 
 * @param {String} companyNm
 * @param {boolean} isOneBill
 * @param {String} isOneVEnabled
 * **/
vzgn.setCompOBCookie = (companyNm, isOneBill, accountRegion, isOneVEnabled) => {
  //console.log("inside setCompOBCookie");
  if (companyNm == null || companyNm == "") {
    companyNm = " ";
  }
  if (isOneBill != null && isOneBill == "true") {
    isOneBill = "true";
  } else {
    isOneBill = "false";
  }
  if (companyNm.length > 22) {
    companyNm = companyNm.substring(0, 20) + "...";
  }
  if (typeof isOneVEnabled === "string") {
    switch (isOneVEnabled.toLowerCase()) {
      case "true":
        isOneVEnabled = true;
        break;
      default:
        isOneVEnabled = false;
    }
  }
  var oneBillCompVal = isOneBill + "||" + companyNm + "||" + accountRegion + "||";
  if (typeof isOneVEnabled === "boolean" && isOneVEnabled) {
    oneBillCompVal += "1V||";
  }
  var amVal = gnav.getCookie(gnlin_amIDCookieName);
  if (amVal != null) {
    if (amVal.length > 10) {
      oneBillCompVal += amVal.substring(amVal.length - 11);
    } else {
      oneBillCompVal += amVal;
    }
  }
  //console.log("oneBillCompVal - test", oneBillCompVal)
  gnav.createCookie(gnlin_oneBillCompCookieName, oneBillCompVal, 0);
  
  window.vzwgnav.setMyVerizonUrl();
  
  // vzw_gnjq.cookie(gnlin_oneBillCompCookieName, oneBillCompVal, { path: "/", domain: vzgn.getGnDomain() });
}
window.setCompOBCookie = vzgn.setCompOBCookie;
/** getGnServicesInfo Should be called from globalHeader.js in componentDidMount()
 * **/
vzgn.getGnServicesInfo = () => {
  //console.log("inside getGnServicesInfo");
  var ocAMVal = null;
  var oneBillCompVal = gnav.getCookie(gnlin_oneBillCompCookieName);
  var amVal = gnav.getCookie(gnlin_amIDCookieName);
  if (oneBillCompVal != null && amVal != null) {
    if (amVal.length > 10) {
      amVal = amVal.substring(amVal.length - 11);
    }
    ocAMVal = vzgn.getAMVal(oneBillCompVal);
    if (ocAMVal != amVal) {
      gnav.createCookie(gnlin_oneBillCompCookieName, null, 0);
      // vzw_gnjq.cookie(gnlin_oneBillCompCookieName, null, { path: "/", domain: vzgn.getGnDomain() });
      oneBillCompVal = null;
    }
  }
  //console.log("Test Console", oneBillCompVal)
  if (oneBillCompVal == null || oneBillCompVal == "") {
    var url = "https://preprodbillpaysvc.verizonwireless.com/vzwsvc/asc" + '/GlobalNavServicesInfo.action'; //
    if (gnlin_gnServScrCallEnabled == "Y") {
      //console.log("script call to server");
      var promiseObj = new ES6Promise((resolve, reject) => {
        try {
          vzgn.loadScript(url, "onebill_company");
          reject("scriptLoaded");
        } catch (e) {
          reject(e);
        }
      })
      return promiseObj;
    } else {
      // Call API to get the data
      return gnav.makeAjaxCall(url, "GET");
      // return gnav.makeAjaxCall("http://localhost:3000/myv", "GET");
      // vzgn.callAjaxJsonp(url, null, vzgn.populateGNServicesInfo, vzgn.popGNErrResp, gnlin_ascTimeOut, "populateGNServicesInfo");
    }
  } else {
    var promiseObj = new ES6Promise((resolve, reject) => {
      reject("cookieOCExists");
    })
  }
  return promiseObj;
}
vzgn.getAMVal = (ocCookie) => {
  var amVal = null;
  if (ocCookie != null) {
    var ocArr = ocCookie.split("||");
    if (ocArr != null && ocArr.length >= 4) {
      amVal = ocArr[3];
      if (amVal == "1V") {
        amVal = ocArr[4];
      }
    }
  }
  return amVal;
}
vzgn.loadScript = (url, scriptId) => {
  vzgn.removeJsIfPresent(scriptId);
  var scriptEl = document.createElement('script');
  scriptEl.setAttribute("type", "text/javascript");
  scriptEl.setAttribute("src", url);
  scriptEl.setAttribute("id", scriptId);
  document.getElementsByTagName("head")[0].appendChild(scriptEl);
}
vzgn.removeJsIfPresent = function (scriptId) {
  var elem = document.getElementById(scriptId);
  if (elem) {
    elem.parentNode.removeChild(elem);
  }
}
vzgn.populateGNServicesInfo = (data) => {
  //console.log("inside populateGNServicesInfo",data);
  if (data == null || data.statusCode == null || data.statusCode != 0) {
    //console.log("Error in vzgn.populateGNServicesInfo")
  } else {
    //for (var i = 0; i < data.cookieInfo.length; i++) {
    //  gnav.createCookie(data.cookieInfo[i].cookieNm, data.cookieInfo[i].cookieVal, 0)
    //}

    vzgn.oneV = gnav.getCookie(gnlin_oneVZ);
    if (data != null && data.oneBillCompStatusCode == 0) {
      //console.log(data.oneVerizon,"data.oneVerizon")
      vzgn.setCompOBCookie(data.companyName, data.isOneBill + "", data.accountRegion, data.oneVerizon);
      vzgn.acctRegion = data.accountRegion;
    }
    // vzgn.postProcessMenu(); Not Required, just for reference
    // Render different types of Verizon combination
    // console.log(vzgn.postProcessMenu())
  }
}
window.populateGNServicesInfo = vzgn.populateGNServicesInfo;


vzgn.postProcessMenu = () => {
  var ocCookie = gnav.getCookie(gnlin_oneBillCompCookieName);
  var isOneBill = "false";
  var isAlltel = "false";
  var compNm = " ";
  if (ocCookie != null) {
    //console.log("ocCookie",ocCookie)
    var ocArr = ocCookie.split("||");
    if (ocArr != null && ocArr.length >= 1 && ocArr[0] == "true") {
      isOneBill = "true";
    }
    if (ocArr != null && ocArr.length >= 2) {
      vzgn.acctRegion = ocArr[2];
    }
    if (ocArr != null && ocArr.length >= 3) {
      if (ocArr[3] == "1V") {
        vzgn.oneV = true;
      }
    }
  }
  var alltelcookie = gnav.getCookie(gnlin_alltelCookieName);
  if (alltelcookie != null) {
    isAlltel = alltelcookie;
  }
  // if (isAlltel == 'true') {
  //   if (typeof vzgnAlltellPostProcess != "undefined") {
  //     vzgnAlltellPostProcess();
  //   }
  // }
  return {
    isAlltel: isAlltel,
    isOneBill: isOneBill,
    oneV: vzgn.oneV
  }

  // if (isOneBill == 'true') {
  //   if (typeof vzgnOneBillPostProcess != "undefined") {
  //     vzgnOneBillPostProcess();
  //   }
  // }
  // if (vzgn.oneV) {
  //   {
  //     vzgn.vzgnOneVerizon();
  //   }
  // }
}
// vzgn.setCompOBCookie("ada", "false", "asdasd", "false")