var preact = window.preact;
var h = preact.h;
var Component = preact.Component;
var render = preact.render;

// The following function will set a cookie which is used by citrix users to suppress or allow video autoplay
(function(){//NoVideoCitrixWLS
	var citrixUser = navigator.userAgent.indexOf('NoVideoCitrixWLS') !== -1,
			showVideo = location.href.indexOf('video=y') !== -1;	
	if(citrixUser || showVideo){
		var val = showVideo ? 'n' : 'y';
		gnav.createCookie('suppressvideo',val)
	}
})();

if(!gnav.getCookie('GLOBALID')){
	gnav.newGID = uuidv4().replace(/-/g,'+')
	gnav.createCookie('GLOBALID', gnav.newGID, 60);
}
 
!gnav.getCookie("loggedIn") && gnav.eraseCookie('wishListCount')
gnav.biz && gnav.createCookie('mybizCookie', 'true');





	if(!gnav.cnst.runningFromProd || window.location.host.indexOf('www')){// TEMPORARY CONDITION TO REDUCE CALLS TO PZN SERVICE
		if (gnavOptions.version === "service" && gnav.getCookie("loggedIn") && !gnav.getCookie("OS") && !gnav.getCookie("featurePers") && gnav.getCookie("role") !== "prepay" && gnav.getCookie("role") !== "guest") {
				var jsonUrl = gnav.cnst.APP_DOMAIN+'/discover/gn_pzn';
				if(gnav.cnst.runningLocalEnv){
					jsonUrl = '/api/gn_pzn.json'
				}
		    var objParam = {};
		    objParam.headers =  [{ name: "Content-type", value: "text/plain" }];
		    gnav.makeAjaxCall(jsonUrl, "GET", objParam, true).then(function (response) {
		        var result = response.data;
		        
		        gnav.createCookie("OS", result.OS, 0.04);
		        gnav.createCookie("featurePers", result.feature_links.join('|'), 0.04);
		        result.customer_type && gnav.createCookie("customerType", result.customer_type, 0.04);
		        result.oneVerizon && gnav.createCookie("oneV", result.oneVerizon, 0.04);
		        
		        if(window.vzwgnav){
		        	window.vzwgnav.setState({customerType:result.customer_type, userOS:result.OS, featurePers:result.feature_links, oneV: result.oneVerizon},window.vzwgnav.setMyVerizonUrl)
		        }
		    });
		}
	}









var locationIndicator = false;
// Main class for global nav
class GlobalNav extends Component {
  binder(funcs){
  	let t = this
  	funcs.map((func) => {
  		t[func] = t[func].bind(t);
  	})
  }
  constructor() {
    super();
    this.role = gnav.getCookie("role") && gnav.getCookie("role").toLowerCase(),
    this.state = {
      variation: gnavOptions.variation || null,
      cqData: null,
      signInOverlay: false,
      searchPopularFlag: true,
      searchResultOverlay: false,
      location: "Set Location",
      searchPopular: null,
      searchResults: null,
      searchProducts:null,
      hamburgerMenu: false,
      cartCount: null,
      menubarWrapper: false,
      shiftMe: false,
      overlayType: null,
      linkToRender: false,
      nextLinkToRender: false,
      mouseOverNavLink: false,
      searchError: false,
      searchString: null,
      deskSubMenuDelay: 1000,
      isFetchingSearch: false,
      isLoggedIn: gnav.getCookie("loggedIn"),
      isBusiness: gnav.biz, //gnav.biz, // gnav.getCookie("mybizCookie"),
      isService: gnav.getCookie("loggedIn") && gnavOptions.version === "service",
      isPrivacy: gnavOptions.version === "privacy",
      isMobile: gnav.mobilecheck(),// this property is true for tablets as well which display the desktop nav.
      isMobileMenu: screen.width < 768, // this property is based on screen width rather than user agent
      userName: null, //gnav.getCookie("greetingName"),
      userRole: this.role ? this.role === "accountmanager" ? "accountholder" : this.role: "",
      displayRole: this.role ? this.role === "accountmanager" ? "Account Manager" : this.role === "accountholder" ? "Account Owner" : "" : "",
      
      userOS: gnav.getCookie("OS"),
      customerType: gnav.getCookie("customerType"),
      oneV: gnav.getCookie("oneV"),
      featurePers : gnav.getCookie("featurePers") ? gnav.getCookie("featurePers").split('|') : [],
      
      accessoryCart: gnav.getCookie("AccessoryOnlyCartAvailable") === 'true' ? true : false,
      prospectCart: gnav.getCookie("prospectCartAvailable") ? true : false,
      prepayCart: gnav.getCookie("isPrepayCartExist") ? "/od/prepaid/cart/" : false,
      accountDropdown: false,
      searchFocusTime: 0,
      ribbonOverlay: false,
      selectedParentLevel: gnav.getCookie("selectedParentLevel"),
      selectedElementId: gnav.getCookie("selectedElementId"),
      planHelpModal: false,
      touchScreen: false,
      notifications: gnav.getCookie("MyVNotifCountCookie") === 'notifPresent',
      searchURL: gnav.cnst.SEARCH_DOMAIN + "/onesearch/search", 
      typeAheadURL: gnav.cnst.SEARCH_DOMAIN + "/onesearch/typeahead?q=",
      searchInputName: "q" 
    };
    
    this.handleNewSearch = gnav.debounce(this.handleNewSearch, 250).bind(this);
    this.binder(['checkKeyboardUser'
    	,'handleAccountDropdownOpen'
    	,'handleAccountDropdownClose'
    	,'handleSignIn'
    	,'handleSearchFocus'
    	,'handleOverlayClose'
    	,'handleMenuOverlay'
    	,'handleHamburgerMenu'
    	,'handleKeyUpHamburgerMenu'
    	,'handleMouseLeave'
    	,'handleSubmenuBack'
    	,'handleActiveState'
    	,'handleLogoFocus'
    	,'handleSearchMenuEnd'
    	,'handleAccountMenuEnd'
    	,'handleMenubarKeyup'
    	,'handleAccountDropdownToggle'
    	,'handleSignOutUrl'
    	,'closeDeskSubMenu'
    	,'toggleRibbonOverlayModal'
    	,'togglePlanHelpModal'
    	,'prepareCartFromStorage'
    	,'setGnCartCookie'
    	,'handleClickSearchListItem'
    	,'handleSkipToMainContent'
    	,'handleSearchTermHover'
    	,'hideNavInCheckout'
    	,'updateLoggedInCookie'
    	,'checkandSetAffiliateCookie'
    	,'handleMobileRender'
    	,'setCqData'
    	,'checkConditions'
    	,'toggleAriaHidden'
    	,'searchOnSubmit'
    	,'setIconCounts'
    	,'setESsearchVal'

    ]);
  }
  
  setCqData(userData){
  	if(!gnav.cnst.runningFromProd && location.href.indexOf('prodTest=1')<0){
    	// replace prod host values with lower env or prod-test host values
    	
    	let myData = JSON.stringify(userData)
    	
    	if(gnav.cnst.APP_HOST == 'www-test'){
    		myData = myData.replace(/www.verizonwireless/g, gnav.cnst.APP_HOST+'.verizonwireless')
    	}

    	if(gnav.cnst.runningLowerEnv || gnav.cnst.runningLocalEnv){
    		
    		let replaceString = gnav.cnst.MYV_HOST + '.verizonwireless.com/ui',
						regex = new RegExp(replaceString,"g");
    		myData = myData.replace(regex, gnav.cnst.APP_HOST+'.verizonwireless.com/ui')
    		
    		let replaceStringM = 'https://myvprepay.',
    				regexM = new RegExp(replaceStringM,"g");
    		if(gnav.cnst.APP_HOST == 'vzwQA1'){myData = myData.replace(regexM, 'https://prepay-east-yz-dev1.')}
    		if(gnav.cnst.APP_HOST == 'vzwQA2'){myData = myData.replace(regexM, 'https://prepay-east-yz-dev2.')}
    		if(gnav.cnst.APP_HOST == 'vzwQA3'){myData = myData.replace(regexM, 'https://prepay-west-yz-dev1.')}
	
    	}
    	
      this.setState({ cqData: JSON.parse(myData) },()=>{
      	const stickyMsgBar = (gnav.dom('#vzw-gn.sticky') && !gnavOptions.hidePromoRibbon && this.state.cqData['messaging-bar'])
      	this.setState({ stickyMsgBar: stickyMsgBar})
      });  
      
      /* replace /my-verizon/ login URL in lower envs */
      if(gnav.cnst.runningLowerEnv && gnav.cnst.AM_HOST.substr(0,13) !== 'accessmgrqa10'){
      	var url = 'https://'+ gnav.cnst.AM_HOST.substr(0,12) +'.verizonwireless.com/vzauth/UI/Login';
      	this.state.cqData.accountDropdown.signedOut.subMenu[0].URL = url;
      	//this.state.cqData.accountDropdown.signedOut.subMenu[0].mobileURL = url;
      }
    }else{
      this.setState({ cqData: userData });
    }
    window.gnavOptions.gnavReady && window.gnavOptions.gnavReady()
    this.setESsearchVal()
  }
  componentDidUpdate() {  	
  	if(this.state.stickyMsgBar)	{
  		const msgHeight = gnav.dom('#vzw-gn .promo-ribbon-2').offsetHeight,
						spacerHeight = document.body.offsetWidth<768 ? 80 : 120
					
			gnav.dom('#vzw-gn .sticky-spacer').style.height = spacerHeight+msgHeight+'px'
	  }
	  if(!this.state.auth && window.vzwDL && window.vzwDL.authentication){	
  		let custType = window.vzwDL.authentication.ecpdId == '2311723'
            ? 'BEST-Device'
            : window.vzwDL.authentication.vct == 'BE' && this.role == 'mobilesecure'
            ? 'business'
            : this.state.customerType

  		this.setState({ customerType:custType, auth: true });
  		//console.log('--customerType',this.state.customerType)
  	}
  	this.state.isMobile = gnav.mobilecheck();

  	if(gnav.dom('.a11y-tooltip-wrapper')){
  		this.state.tooltipRendered = true
  	}
      if(this.state.ribbonOverlay){
          const modalChild = gnav.dom('#gnavModal'),
                modal = document.createElement('div')
          modal.id = 'gnavModalWrapper'
          modal.appendChild(modalChild)
          document.body.insertBefore(modal, document.body.firstElementChild);
      }

  }

  componentDidMount() { 	
  	let _this = this;

		/** get proper JSON customer, prospect, business [START] **/
		if(this.state.variation === 3){  
			this.setState({cqData:{}});
		}else{
	    let JSON_CONTRACT_URL = this.state.isPrivacy
	    	? gnav.cnst.HEADER_JSON_URL_PRIVACY
	    	: this.state.isBusiness
		      ? gnav.cnst.HEADER_JSON_URL_BIZ
		      : this.state.isLoggedIn
		        ? this.state.isService
				      ? gnav.cnst.HEADER_JSON_URL_SERVICE
				      	: gnav.cnst.HEADER_JSON_URL_CUSTOMER
		        	: gnav.cnst.HEADER_JSON_URL_PROSPECT
		        	
		    // special condition for nsa
		    
		    if(JSON_CONTRACT_URL == gnav.cnst.HEADER_JSON_URL_CUSTOMER && (gnavOptions.nsa || /NSA_REDIRECT_TH/.test(gnav.getCookie("cdlThrottleList")))){
		    	JSON_CONTRACT_URL = gnav.cnst.HEADER_JSON_URL_CUSTOMER_NSA
		    }

		    gnav.makeAjaxCall(JSON_CONTRACT_URL, "GET").then(function (userData) {	
		    	_this.setCqData(userData)   	
		    	
		    	if(userData.cradleConfigurations && userData.cradleConfigurations.cradleKillSwitch == 'true' && gnav.getCookie('gnavThrottleList') !== 'false'){
		    		gnav.createCookie('gnavThrottleList','none')
		    		gnav.createCookie('LoggedOutAllocation','none',-1)
		    		gnav.createCookie('LoggedInAllocation','none',-1)
		    	}
		    	
		    });	
		}
    /** get proper JSON customer, service, prospect, business [END] **/

    /** Get location form cookie [START] **/
    let location = {};
    location.state = gnav.getCookie("STATE");
    location.city = gnav.getCookie("CITY");
    if (location.state && location.city) {
      _this.setState({ location: location });
    }
    /** Get location form cookie [END] **/
    
    /** My Verizon Functionality [START] **/
    
    if (this.state.isLoggedIn && this.state.oneV) {
    	this.setMyVerizonUrl()
    }
    /*
    if (this.state.isLoggedIn) {
      let ocCookie = gnav.getCookie('OC');
      let oneVCookie = gnav.getCookie('oneV');
      var oneBillURL = "";
      var temp = true;
      if (temp && !ocCookie && !gnav.cnst.runningLowerEnv && !oneVCookie) {
        if (gnav.cnst.runningFromProd || gnav.cnst.runningFromProdTest) {
          oneBillURL = "https://billpaysvc"
        } else {
          oneBillURL = "https://preprodbillpaysvc"
        }
        oneBillURL += ".verizonwireless.com/vzwsvc/asc/GlobalNavServicesInfo.action";
        
        //uncomment next line for local testing
        //oneBillURL = "/api/newglobalnavservicesinfo.js";

        let addScript = document.createElement('script');
        addScript.type = 'text/javascript';
        addScript.src = oneBillURL;
        (document.getElementsByTagName('body')[0]).appendChild(addScript);
        
        // see setMyVerizonUrl function below which is called after this script is processed in my-verizon.js
      } else {
      	_this.setMyVerizonUrl()
      }   
    }
    /** My Verizon Functionality [END] **/
    
    this.setIconCounts()
    
    this.hideNavInCheckout();
    //this.updateLoggedInCookie();
    this.checkandSetAffiliateCookie();
    
    
    if(/verizonwireless.com/.test(location.host)){
	    setTimeout(function(){

		    if(false && !gnav.getCookie("GLOBALID")){
					gnav.makeAjaxCall(gnav.cnst.APP_DOMAIN+"/od/globalCookies", "GET")
					.then(function () {	
			    	gnav.createCookie("GLOBALID",gnav.getCookie("GLOBALID"),3650)
			    })
			    
			    
				}else{
					gnav.createCookie("GLOBALID",gnav.getCookie("GLOBALID"),3650)
				}
	
			},1000);
	  }
	 
	}
	
	setESsearchVal(){
		const esObj = {
					MobileSupport:['.com/support/','.com/solutions-and-services/'],
					MobileMyVerizon:['.com/ui/','.com/vzw/','.com/myv/','.com/blocks/','.com/my-verizon/'],
					MobileAccessories:['.com/products']
				}			
		let siteSection,
				idx,
				esMatch
		
		for (siteSection in esObj) {		
			if(esMatch){
				break
			} else {
				for(idx in esObj[siteSection]){
					let compare = esObj[siteSection][idx]
					if(window.location.href.indexOf(compare) > -1){
						esMatch = true
						this.setState({ESsearchVal: siteSection})
						break
					}
				}
			}
		}
		
		!esMatch && this.setState({ESsearchVal: 'MobileShop'})
		
	}
	
	setIconCounts() {
		// cart and wishlist count icons
		let accessoryCart = gnav.getCookie("AccessoryOnlyCartAvailable"),
            prospectCart = gnav.getCookie("prospectCartAvailable"),
            cartCountCookieName = this.state.prepayCart
    		? "prepayCartCount"
    		: this.state.isLoggedIn && gnav.getCookie('gnCartCount')
    		? "gnCartCount"
    		: "prospectCartCount",
				cartCount = parseInt(gnav.getCookie(cartCountCookieName)),
				wishListCount = parseInt(gnav.getCookie('wishListCount'))

      this.setState({ cartCount: cartCount, wishListCount: wishListCount, accessoryCart: accessoryCart, prospectCart: prospectCart });
	}
  
  setMyVerizonUrl() {
  	let conditions = this.state.oneV.toString() || vzgn.postProcessMenu();
    if (conditions == 'true' || conditions.oneV) {
      if (gnav.cnst.runningFromProd || gnav.cnst.runningFromProdTest) {
        //this.setState({ myVerizonUrl: "https://login.verizonwireless.com/accessmanager/accountholder/c/residentiallogin?goto=https%3A%2F%2Fwww.verizon.com%2Fforyourhome%2Fmyaccount%2Fngen%2Fpr%2Fhome%2Fmyverizon.aspx&clientId=vzwtogglenav" })
        this.setState({ myVerizonUrl: "https://login.verizonwireless.com/accessmanager/accountholder/c/residentiallogin?goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter&clientId=vzwtogglenav" })
      } else {
        //this.setState({ myVerizonUrl: "https://tlogin.verizonwireless.com/accessmanager/accountholder/c/residentiallogin?goto=https%3A%2F%2Fwww25.verizon.com%2Fforyourhome%2Fmyaccount%2Fngen%2Fpr%2Fhome%2Fmyverizon.aspx&clientId=vzwtogglenav" })
        this.setState({ myVerizonUrl: "https://tlogin.verizonwireless.com/accessmanager/accountholder/c/residentiallogin?goto=https%3A%2F%2Fwww.verizon.com%2Fconsumer%2Fmyverizon%2Frouter&clientId=vzwtogglenav" })
      }
    }
  }
  
  handleCartRetrival() {
    var cartItemsJSON = localStorage.getItem("commerceJSON");
    var cartItemsDate = localStorage.getItem("commerceJSONDate");
    var sessionConfCookie = gnav.getCookie("vsi");
    var persistCookie = gnav.getCookie("persistCart");
    var removePerCart = gnav.getCookie("removePerCart");

    var cartValidity = gnav.calculateCartDifference(new Date(cartItemsDate), new Date());

    // Remove localStorage data related to cart if the cart item date is expired
    if ((cartItemsDate != null && cartValidity >= 29) || removePerCart == "true") {
      localStorage.removeItem("commerceJSON");
      localStorage.removeItem("commerceJSONDate");
      if (removePerCart == "true") {
        gnav.eraseCookie("removePerCart");
      }
      return;
    }

    if (cartItemsJSON != null && cartItemsJSON != "undefined") {

      var cartJSON = JSON.parse(cartItemsJSON);
      var cartVal = Number(cartJSON["accessoriesqty"]) + Number(cartJSON["devicesqty"]);
      if (persistCookie != "true" && persistCookie == null) {
        if (sessionConfCookie == undefined) {
          if (cartVal != null && cartVal != undefined) {
            this.prepareCartFromStorage(cartItemsJSON)
            this.setGnCartCookie(cartVal);
          }
        } else {
          // console.log("sessionConfCookie is defined and cartJSON exists");
          if (cartVal != null && cartVal != undefined) {
            this.prepareCartFromStorage(cartItemsJSON)
            this.setGnCartCookie(cartVal);
          }
        }
      } else {
        // console.log("persistCookie true");
        this.setGnCartCookie(cartVal);
        gnav.createStorageFromCart();
      }
    } else {
      // console.log("cartItemsJSON null");
      if (sessionConfCookie) {
        gnav.createStorageFromCart();
      }
      gnav.createCookie("persistCart", true, 0);
      // vzw_gnjq.cookie("persistCart", true, { path: "/" });
    }
  }
  prepareCartFromStorage(cartItemsJSON) {
    var _this = this;
    if (cartItemsJSON != null && cartItemsJSON != "undefined") {
      var headers = [{ name: "Content-type", value: "application/x-www-form-urlencoded; charset=utf-8" }, { name: "X-Requested-With", value: "XMLHttpRequest" }];
      var objParam = {};
      objParam.headers = headers;
      cartItemsJSON = JSON.parse(cartItemsJSON);
      cartItemsJSON.oneDigital = true;
      cartItemsJSON = JSON.stringify(cartItemsJSON);
      objParam.postData = "commerceItems=" + encodeURIComponent(cartItemsJSON);
      let cartRetrieveURL = !!this.state.cqData.cartRetrieveURL ? this.state.cqData.cartRetrieveURL : gnav.cnst.APP_DOMAIN+"/vzw/baseajaxservlet?ajaxName=addLocalStorageCartItems";
      gnav.makeAjaxCall(cartRetrieveURL, "POST", objParam).then(function (resp) {
        if (resp == "success") {
          // console.log("success");
          gnav.createCookie("persistCart", true)
        } else {
          // console.log("failed");
          gnav.eraseCookie("persistCart");
          gnav.eraseCookie("gnCartCount");
          _this.setState({ cartCount: null });
        }
      }).catch(function () {
        // console.log("error, catch");
        gnav.eraseCookie("persistCart");
        gnav.eraseCookie("gnCartCount");
        _this.setState({ cartCount: null });
      })
    }
  }
  setGnCartCookie(cartCount) {
    var _this = this;
    // console.log("in setGnCartCookie");
    var cartCountCookieName = "gnCartCount";
    // var vzwCfg = { path: '/', domain: '.verizonwireless.com' };
    // var vzwcorpCfg = { path: '/', domain: '.vzwcorp.com' };
    var cartCookieVal = gnav.getCookie(cartCountCookieName);
    var persistCookie = gnav.getCookie("persistCart");
    if (cartCount !== undefined && cartCount !== "" && cartCount !== "0") {
      if (!cartCookieVal || cartCookieVal != cartCount) {
        // vzw_gnjq.cookie(cartCountCookieName, cartCount, vzwCfg);
        // vzw_gnjq.cookie(cartCountCookieName, cartCount, vzwcorpCfg);
        gnav.createCookie(cartCountCookieName, cartCount);//, 0);
      }

    } else {
      if (cartCookieVal !== undefined && cartCookieVal.length > 0) {
        // Remove gnCartCount cookie if cartCount and gnCartCount doesn't match

        // vzw_gnjq.cookie(cartCountCookieName, '0', vzw_gnjq.extend({ expires: -1 }, vzwCfg));
        // vzw_gnjq.cookie(cartCountCookieName, '0', vzw_gnjq.extend({ expires: -1 }, vzwcorpCfg));
        gnav.eraseCookie(cartCountCookieName);
        _this.setState({ cartCount: null });
      }
      if (!persistCookie) {
        // Remove highlightCount in the HTML

        // vzw_gnjq('#vgn_shcart a .highlightCount').remove();
      }
    }
    //update view
    // updateGnCartCount();
  }
  /** Location Indicator [START] **/
  checkLocation(location) {
    return (location.city && location.city.length > 0 && location.state.length) ? location.city + "," + location.state : location;
  }
  /** Location Indicator [END] **/

  /** Check keyboard user for Accessibility [START] **/
  checkKeyboardUser(e) {
  	//console.log('checkKeyboardUser',e.which)
    const isKeyboardUser = [9, 13, 32, 37, 38, 39, 40].indexOf(e.which) !== -1;
    this.setState({ isKeyboardUser: true });
    if (isKeyboardUser && document.body.className.indexOf("isKeyboardUser") === -1) {
      document.body.className += " isKeyboardUser";
    }
    if(e.which === 27 && this.state.searchResultOverlay){
    	this.handleOverlayClose(e)
    }
  }
  /** Check keyboard user for Accessibility [END] **/

	/** a11y method to move focus to next or previous top-level menubar link
			onKeyup of left or right arrow keys **/
  handleMenubarKeyup(e) {
    let key = e.which,
      targ = e.target
    switch (key) {
      case 37:
        // console.log('left arrow', e.which)
        if (targ.parentElement.previousSibling) {
          targ.parentElement.previousSibling.firstChild.focus()
        } else {
          this.closeDeskSubMenu(e)
        }
        break

      case 38:
        // console.log('up arrow', e.which)
        break

      case 39:
        //				console.log('right arrow', e.which,targ.parentElement.nextSibling)
        if (targ.parentElement.nextSibling) {
          targ.parentElement.nextSibling.firstChild.focus()
        } else {
          this.closeDeskSubMenu(e)
          gnav.dom('#navPhoneSearch').focus()
        }
        break

      case 40:
        // console.log('down arrow', e.which)
        break
    }
  }
  /** a11y method [END] **/

  handleSignOutUrl(e) {
    if(e.target.href.indexOf('GLOBALNAV_SIGNOUT_FUNCTION') !== -1 || (e.target.text.substr(0,4) === 'Sign' && gnavOptions.signOutUrl )) {      
      e.preventDefault()
      if (gnav.getCookie("gnCartCount")) {
      	gnav.eraseCookie("gnCartCount");
      }
      gnav.eraseCookie(["OS", "featurePers"]);
      location.href = gnav.cnst.SIGNOUT_URL;
    }
    document.getElementById('hamburgerMenuCloseBtn').click()
  }

	/** Method to move focus to last link in menubar when a11y user back-tabs from search
			Focus goes to logo so we need to move it to last menubar item **/
  handleLogoFocus(e) {
    //console.log('handleLogoFocus')
    if (this.state.searchResultOverlay) {
      this.handleOverlayClose(e)
      setTimeout("gnav.dom('#vzw-gn .stores-option a').focus()", 1)
      //console.log('handleLogoFocus', gnav.dom('#vzw-gn .global-nav-list.menubar>li:last-of-type a'))
    }
  }
  /** Method to close search menu when focus moves out of search menu in desktop **/
  handleSearchMenuEnd(e) {
    if (this.state.searchResultOverlay && !this.state.isMobile) {
      gnav.dom('#navPhoneSearch').focus();
    }
  }
  /** Method to close account menu when focus moves out of search menu in desktop **/
  handleAccountMenuEnd(e) {
    if (!e.target.parentElement.nextSibling && !this.state.isMobile) {
      this.handleAccountDropdownClose(e)
      //gnav.dom('#gnavAccountMenu').focus()
    }
  }
  /** Update cart cookie for existing customer flow  **/
  updateLoggedInCookie() {
    try {
      if (this.state.isLoggedIn) {
        if (gnav.getCookie("cartCount")) {
          gnav.createCookie("gnCartCount", gnav.getCookie("cartCount"));
          this.setState({ cartCount: gnav.getCookie("cartCount") });
        }
      }
    } catch (err) {
    }
  }

  /** Checkout Nav hide function  **/
  hideNavInCheckout(){
    try{
      if(vzwDL){
        if(vzwDL.page.pageName.indexOf('checkout')!== -1 && vzwDL.page.pageName.indexOf('order confirmation')=== -1) {
         this.setState({ hideNavInCheckout: true });
        }
        if(vzwDL.page.pageName.indexOf('order confirmation')!== -1) {
         this.setState({ hideCartIcon: true });
        }
       }
    }catch(err){
       }
   }
  /** Setting Affiliate Cookie  **/
  checkandSetAffiliateCookie() {
     try{
      if(window.location.href.indexOf('vendorid=')!== -1){
        var vendor_id = /vendorid=([^&]+)/.exec(window.location.href);
        if(vendor_id){
        gnav.createCookie("AFFILIATE", vendor_id[1], 60);
        }
      }
     }catch(err){
       }
  }
  /** Search functionality [START] **/
  handleSearchFocus(e) {
  	e.preventDefault()
  	gnav.dom('#notifBarContainer #toggle-button') && gnav.dom('#notifBarContainer #toggle-button').click()
  	
    let _this = this,
      	now = new Date().getTime()
    if (!this.state.searchResultOverlay && now > this.state.searchFocusTime + 10) {
    	setTimeout(function(){
    		window.vzwgnav.setState({
        searchPopular: _this.state.cqData.searchPopular,
        searchResultOverlay: true,
        hamburgerMenu: true
      },function(){gnav.dom('#navPhoneSearch').focus();window.vzwgnav.toggleAriaHidden('search',true) });
    	},10)  
    }
  }
  
  searchOnSubmit(e) {
  	!e.target.querySelector('#navPhoneSearch').value && e.preventDefault()
  }
  
  handleNewSearch(e) {
  	//console.log('handleNewSearch')
    let searchString = e.target.value;
    if (/\s+$/.test(searchString)) {
      return;
    }
    
    let _this = this,
    		searchURL = !!this.state.cqData.searchURL ? this.state.cqData.searchURL : this.state.typeAheadURL,
    		ecomSessionCookie = gnav.getCookie("ECOMM_SESSION"),
    		exceptions = this.state.cqData.searchPopular.exceptions,
    		searchExceptions = exceptions ? exceptions.split(',') : ['lg','5g']
    this.setState({ isFetchingSearch: true });
    if (searchString.length > 2 || searchExceptions.indexOf(searchString.toLowerCase()) > -1) {
      if(this.state.cqData && this.state.cqData.searchTermPlaceHolder) {
        let searchPattern = new RegExp(this.state.cqData.searchTermPlaceHolder, 'g');
        searchURL = searchURL.replace(searchPattern, searchString);
      } else {
        searchURL += searchString + "*";
      }
      if(ecomSessionCookie && !gnav.getCookie("SEARCH_ECOMM_SESSION")){
        gnav.createCookie("SEARCH_ECOMM_SESSION", ecomSessionCookie, 0.021);
      }
      
      if(window.vzdl && window.vzdl.search){
      	window.vzdl.search.term = searchString;
      } 
      
      gnav.makeAjaxCall(searchURL, "GET").then(function (searchData) {
        _this.setState({ searchResults: searchData, searchProducts:searchData.products, searchString: searchString, searchError: false, isFetchingSearch: false });
      }).catch(function () {
        _this.setState({ searchError: true, searchString: searchString, isFetchingSearch: false })
      });
    } else {
      _this.setState({ searchError: true, searchString: null, isFetchingSearch: false })
    }
  }
  /** Search functionality [END] **/

  /** Search term hover functionality [START] **/
  handleSearchTermHover(searchTerm){
    let _this = this;
    let searchString = searchTerm;
    if (/\s+$/.test(searchString)) {
      return;
    }
    this.setState({ isFetchingSearch: true });
    if (searchString.length > 2) {
      gnav.makeAjaxCall(this.state.typeAheadURL + searchString + "*", "GET").then(function (searchData) {
      //gnav.makeAjaxCall("http://localhost:3000/data/?searchTerm=" + searchString + "*", "GET").then(function (searchData=dummySearchData) {
        if (searchData && searchData.products) {
          _this.setState({searchProducts:searchData.products, searchError: false, isFetchingSearch: false });
        } else {
          _this.setState({ searchError: true, isFetchingSearch: false })
        }
      }).catch(function () {
        _this.setState({ searchError: true, isFetchingSearch: false })
      });
    } else {
      _this.setState({ searchError: true, isFetchingSearch: false })
    }
  }
  /** search term hover funcitonality [END]

  /** Signin Overlay and logic [START] **/
  handleOverlayClose(e) {
    //console.log('handleOverlayClose', e.which)
    this.toggleAriaHidden(this.state.overlayType,false)
    if (gnav.dom("#navPhoneSearch")) {
        gnav.dom("#navPhoneSearch").value = "";
    }

    //console.log('hamburger',hamburger,e.target.getAttribute("class"))
    this.setState({
      searchResultOverlay: false,
      hamburgerMenu: false,
      searchResults: null,
      searchError: false,
      searchString: null,
      isFetchingSearch: false,
      accountDropdown: false,
      searchFocusTime: new Date().getTime()
    },()=> this.closeDeskSubMenu(e));
	;
  }
  handleSignIn(e) {
    this.setState({
      signInOverlay: true
    });
  }

  handleLanguageToggle(e) {
    e.preventDefault();
		/**********************************************
		The MP object is defined in helpers.js
		It is required for Motion Point language toggle
		***********************************************/

    MP.init();

    // UNCOMMENT NEXT LINE FOR LOCAL TESTING
    // MP.SrcUrl = 'https://vzwqa8.verizonwireless.com/smartphones/'

    MP.switchLanguage(location.host.substr(0,2) === "es" ? "en" : "es");
  }
  handleMenuOverlay(e) {
    let _this = this;
    if (!this.state.searchResultOverlay) {
      this.setState({
        searchResultOverlay: true
      });
    }
  }
  /** Signin Overlay and logic [END] **/

  /** Mobile hamburger menu [START] **/
  handleHamburgerMenu(e) {
    this.setState({
      hamburgerMenu: true
    },()=>{this.toggleAriaHidden('hamburger',true);
    	gnav.dom('#hamburgerMenuCloseBtn').focus()}); 
  }
  handlePreventDefaultMobile(data, e) {
    if ((this.state.isMobile || this.state.touchScreen) && (data.subMenu.length > 0 || data.subBottomMenu.length > 0)) {
      //console.log("handlePreventDefaultMobile",e.target);
      e.preventDefault();
    } else if (!this.state.isMobile) {
      gnav.createCookie("selectedParentLevel", e.target.parentElement.id);
      location.href = e.target.getAttribute('href');
    } else if(gnav.isAgentSite && this.state.isMobile) {
      /* Agent site issue fix AVE-2186, links not working when no submenu in it */
      gnav.createCookie("selectedParentLevel", e.target.parentElement.id);
      location.href = e.target.getAttribute('href');
    }
  }
  // Function to activate hamburger menu on pressing enterkey or spacebar
  handleKeyUpHamburgerMenu(e) {
    e.stopPropagation();
    if(e.keyCode == 13 || e.keyCode == 32) {
      this.handleHamburgerMenu(e);
    }
  }
  /** Mobile hamburger menu [END] **/

  // Collapsed nav [START]
  animateSumbenu() {
    //console.log('animateSumbenu')
    this.setState({ shiftMe: true });
  }
  handleMouseover(index, e) {
    if (!this.state.isMobile && !this.state.touchScreen) {
      const closedDelay = gnav.paramVal('closedDelay') ? gnav.paramVal('closedDelay') : 100
      const openDelay = gnav.paramVal('openDelay') ? gnav.paramVal('openDelay') : 600
      const delay = this.state.linkToRender !== false ? openDelay : closedDelay //this.state.deskSubMenuDelay
      //console.log('handleMouseEnter',index,'delay',delay,'shiftMe',this.state.shiftMe)
      this.setState({ nextLinkToRender: index, mouseOverNavLink: true });
      setTimeout(this.openDeskSubMenu.bind(this, index), delay);
    } else {
      this.animateSumbenu.bind(this);
    }
  }
  handleMouseLeave(index, e) {
    //console.log('handleMouseLeave',index)
    this.setState({ nextLinkToRender: false, mouseOverNavLink: false })
  }
  
  openDeskSubMenu(index) {
    if (this.state.nextLinkToRender === index && index !== this.state.linkToRender) {
      //console.log('openDeskSubMenu',index)
      this.setState({ linkToRender: index, shiftMe: false });
      setTimeout(this.animateSumbenu.bind(this), 10);
    }
  }
  closeDeskSubMenu(e) {
    //console.log('closeDeskSubMenu')
    this.setState({ nextLinkToRender: false, linkToRender: false },
    ()=>{
      if (e.target.id === 'gnavCloseSearch' || e.which === 27) {
        gnav.dom('.search-icon').focus();
      }
      else if (e.target.id === 'hamburgerMenuCloseBtn') {
        gnav.dom('#hamburgerMenuOpenBtn').focus();
      }
      else if (e.target.id === 'accountMenuCloseBtn') {
        gnav.dom('#accountMenuOpenBtn').focus();
      }
	  }
    );
  }

  handleSubmenuBack() {
    this.setState({ linkToRender: false, submenuBack: true });
  }

  handleActiveState() {
    if (this.state.isService){
    	var element = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
	    gnav.createCookie("selectedParentLevel", element.id);
	    gnav.createCookie("selectedElementId", event.target.id);
	    window.gnavSetNav = true;
	  }
	  document.getElementById('hamburgerMenuCloseBtn').click()
  }
  /** Collapsed nav [END] **/

  /** User Account Dropdown [START] **/
  handleAccountDropdownClose(e) {
    //console.log("close");
    this.setState({ accountDropdown: false });
  }
  handleAccountDropdownOpen(e) {
    //console.log("handleAccountDropdownOpen");
    if (this.state.isMobile) {
      return;
    }
    this.setState({ accountDropdown: true });
  }
  
  handleAccountDropdownToggle(e) {
    e.preventDefault()
    if( !this.state.isLoggedIn && /dropdown-active/g.test(e.target.parentElement.parentElement.className)){
    	location.href = this.state.cqData.accountDropdown.signedOut.subMenu[0].URL;
    }else{
	    if(!this.state.accountDropdown){
	    	this.toggleAriaHidden('account',true)
	    }
	    this.setState({ accountDropdown: !this.state.accountDropdown },
	    	()=>{
	    		if(this.state.isMobile){
	    			let focusEl = this.state.accountDropdown ? '#accountMenuCloseBtn' : '#accountMenuOpenBtn';
	    			focusEl && gnav.dom(focusEl).focus();
	    		}
	    	}
	    )
	  }
  }
  
  
  /** User Account Dropdown [END] **/
	
	
	handleA11yMainNavLinks(index,e){
		//console.log('handleA11yMainNavLink',index, this.state.linkToRender, this.state.nextLinkToRender)
		e.stopPropagation();
  	this.handleMobileRender(index, e, true)

  	//this.handleMobileRender.bind(this, index, e, true)
  }
  
  
  // Mobile click animation on sublevel menu
  handleMobileRender(index, e, a11y) {
   //console.log("handleMobileRender this.state.linkToRender=", this.state.linkToRender);
		let gotoLink = false;
    if (this.state.isMobile || !this.state.mouseOverNavLink) {//this is mobile or desktop keyboard user
    	//console.log("handleMobileRender - touch or desktop-keyboard");
      this.setState({ shiftMe: true, submenuBack: false });

      if(this.state.linkToRender === index && !this.state.isMobileMenu){
        this.setState({ linkToRender: false });
        e.target.setAttribute('aria-expanded',false)
      } else {
      	if(index===0 && this.state.isService){// special condition for overview link in service gnav. could be better
					gotoLink = true;
      	}else{
	        e.target.setAttribute('aria-expanded',true)
	        window.linkToRender = index;
	        setTimeout(function () {
	          window.vzwgnav.setState({ linkToRender: window.linkToRender });
	        }, 333);
	      }
      }
    }else{// this is desktop main nav link mouse click
    	//console.log("handleMobileRender - mouse");
    	gotoLink = true;
    }
    if(gotoLink){
    	gnav.createCookie("selectedParentLevel", e.target.parentElement.id);
    	//console.log( e.target.nextElementSibling.getAttribute('href'))
    	location.href = e.target.nextElementSibling.getAttribute('href');
    }
    
  }

  // Modal overlay (ribbon promo) functionality - [START]
  toggleRibbonOverlayModal() {
    //console.log('toggleRibbonOverlayModal')
    try{
	    if (this.state.ribbonOverlay) {
	      gnav.dom(".ribbonPromoInfoIcon").focus();
	      //this.removeAriaHidden();
	      this.toggleAriaHidden('modal',false)
            document.body.removeChild(gnav.dom('#gnavModalWrapper'))
	    } else {
	      //this.addAriaHidden();
	      this.toggleAriaHidden('modal',true)
      } 
    }catch(err){}
    this.setState({ ribbonOverlay: !this.state.ribbonOverlay });
  }
  
  		
  // START - Modal Plan Help TEMPORARY
  togglePlanHelpModal(e) {
  	e.preventDefault();
    if (this.state.planHelpModal) {
      gnav.dom(".planHelpModalClass").focus(); 
      //this.removeAriaHidden();
      this.toggleAriaHidden('modal',false)
    }
    else {
      //this.addAriaHidden();
      this.toggleAriaHidden('modal',true)
    }
    this.setState({ planHelpModal: !this.state.planHelpModal });
  }
  // END - Modal Plan Help TEMPORARY
  
  
  toggleAriaHidden(overlay,bool){
  	//console.log('toggleAriaHidden',overlay,bool)
  	
  	gnav.dom('#tooltip .tooltip-close') && gnav.dom('#tooltip .tooltip-close').click()
  	
  	this.setState({overlayType: overlay})
		var toggleNodesGnav = {
				account: ['.logo-container','.search-cart','.menu-container','.account-username-icon','.language-selector','.global-nav-lower'],
				hamburger: ['.logo-container','.search-cart'],
				search: ['.logo-container','.language-account','.menu-container','.global-nav-lower'],
				modal: ['#gnavModalWrapper']},
				toggleNodesDOM = document.body.childNodes;
				
		toggleNodesDOM.forEach(function(x,i){
			if(x.children && x.children.length && (overlay == 'modal' || x.id !== 'vzw-gn') && (overlay == 'modal' || !x.querySelector('#vzw-gn'))){
				x.setAttribute('aria-hidden',bool)
			}
		})
		toggleNodesGnav[overlay] && toggleNodesGnav[overlay].forEach(function(selector){
			document.querySelectorAll(selector).forEach(function(lmnt){
				lmnt.setAttribute('aria-hidden',bool)
			})
		})
	}

  handleEraseMybizcookie() {
    gnav.eraseCookie('mybizCookie')
  }

  //Function to handle search list item click
  handleClickSearchListItem(url){
    if(window.isAgentSite) {
      const urlstring = url.split('/');
      urlstring[1] = "od";
      url = urlstring.join('/');
    } 
    window.location.href = url;
  }
  // Modal overlay (ribbon promo) functionality - [END]

  // Function to handle keyup event for Skip To Main Content link - WE DON'T NEED THIS
  handleSkipToMainContent(e) {
    if(e.keyCode == 13 || e.keyCode == 32 ) {
      gnav.dom('#headerEnd').focus();
    }
  }
  
   
  checkConditions(data){
  	const dc = data.conditions,
  				ts = this.state;

  	if(dc){
  		if(
  		(dc.role && dc.role !== ts.userRole) ||
  		(dc.feature && ts.featurePers.length && ts.featurePers.indexOf(dc.feature)<0) ||
  		(dc.hideFrom && dc.hideFrom.indexOf(ts.customerType)>=0)
  		) return false;
  	}
  	return true     	
  }


  render(props, state) {
    if (!this.state.cqData || this.state.cqData === null) {
      return null;
    }

    // Check if popular search is to render
    const ts = this.state,
    	cq = ts.cqData,
    	cartCount = ts.cartCount,
    	wishListCount = ts.wishListCount,
    	isMobile = ts.isMobile,
    	auth = window.vzwDL && window.vzwDL.authentication || {},
    	userRole = ts.userRole,
    	userOS = ts.userOS,
    	variation = ts.variation,
    	containerClass = variation ? "variation" + variation : "",
    	linkToRender = ts.linkToRender,
    	isLoggedIn = ts.isLoggedIn,
    	featurePers = ts.featurePers,
    	customerType = ts.customerType,
    	searchRenderCondition = (!ts.searchError && ts.searchString && ts.searchString.length > 0) ? true : false,
      hamburgerActive = ts.hamburgerMenu ? "hamburger-active" : "",
      searchActive = ts.searchResultOverlay ? "search-active" : "",
      ariaHideSearchResults = searchActive ? false : true,
      noResultsYet = ts.searchString ? "" : "no-results-yet",
      ribbonPromoText = (!gnavOptions.hidePromoRibbon && cq.ribbon && cq.ribbon.ribbontext) ? cq.ribbon.ribbontext : null, //"Lorem ipsum dolor sit amet, consectetur elit.",//Here is ribbon promo text. This text has a character length of 70 cha.
      hasRibbonPromoClass = ribbonPromoText ? " has-promo-ribbon" : "",
      hasAgentBannerClass = gnav.isAgentSite && !isMobile && cq.agentAuthText ? " has-agt-navbar-top" : "",
      logoHeight = isMobile ? 22 : 40,
      logoUrl = isMobile ? cq.logoUrlMobile : cq.logoUrlDesktop,
      cartLabel = cq.cart ? cq.cart.label : 'cart',
      cartItems = cartCount ? cartCount : 0,
      fullCartLabel = cartLabel + ', ' + cartItems + (cartItems == 1 ? ' item' : ' items'),
      navZIndex = typeof linkToRender === 'number' ? '1100' : '1000',// this is to hide feedback tab when lower menu is open to prevent overlap
      hideCheckoutNav = ts.hideNavInCheckout ? "displayNone" : "",
      hideCartIconInOC = ts.hideCartIcon ? "displayNone" : "",
      notifications = ts.notifications && ts.isService && userRole === "accountholder",
      menuNode = ts.variation == 3
	      ? null
	      : !ts.isLoggedIn
	        ? cq.accountDropdown && cq.accountDropdown.signedOut
	        : ts.isBusiness || cq.accountDropdown.signedIn
	          ? cq.accountDropdown.signedIn
	          : cq.accountDropdown[userRole]
	            ? cq.accountDropdown[userRole]
	            : cq.accountDropdown.accountholder,
      menuHeading = ts.isLoggedIn && ts.userName && ts.userName !== 'null'
        ? ts.userName.length > 10
          ? 'Hi, ' + ts.userName.substring(0, 10) + '...'
          : 'Hi, ' + ts.userName
        : menuNode && menuNode.label,
     	acctNum = ts.isService && ts.displayRole && auth && auth.accountNumber
     			? gnav.acctNumFix(auth.accountNumber)
     			: ''

    let searchTargetURL = !!cq.searchTargetURL ? cq.searchTargetURL : ts.searchURL;
    let searchResultsHeading = "";
    let shopDealsHeading = null;
    let RelatedTermLabel = cq.searchPopular && cq.searchPopular.RelatedTermLabel ? cq.searchPopular.RelatedTermLabel : "";
    let SuggestedTermLabel = (cq.searchPopular && cq.searchPopular.SuggestedTermLabel) ? cq.searchPopular.SuggestedTermLabel : "";
    let cartURL = ts.prepayCart
            ? ts.prepayCart
            : (!isLoggedIn && ts.accessoryCart && !ts.prospectCart ? cq.cart && cq.cart.accURL : cq.cart && cq.cart.URL)
    if(/verizon.com/.test(location.host) && cartURL && !ts.prepayCart) { //CXTDT-48923 special condition to render relative cart URL on verizon domains
        cartURL = cartURL.substr(cartURL.indexOf('.com')+4)
    }
    
    //console.log('-- cq.accountDropdown.signedIn',cq.accountDropdown.signedIn)
    
    if (ts.searchError && ts.searchString) {
      searchResultsHeading = "No results found for &nbsp;" + "\"" + ts.searchString + "\"";
    } else {
      searchResultsHeading = searchRenderCondition ? "Results &nbsp;" + "\"" + ts.searchString + "\"" : RelatedTermLabel;
      shopDealsHeading = searchRenderCondition ? null : SuggestedTermLabel;
    }

    if (isMobile) {
      if ((ts.hamburgerMenu || ts.ribbonOverlay || ts.accountDropdown)) {
        if (document.body.className.indexOf("mobile-scroll-lock") === -1) {
          document.body.className += " mobile-scroll-lock";
        }
      } else {
        if (document.body.className.indexOf("mobile-scroll-lock") != -1) {
          document.body.classList.remove("mobile-scroll-lock");
        }
      }
    }

    /* Agent site specific changes for search */
    const searchInputName = gnav.isAgentSite && typeof (this.state.cqData.searchInputName) !== 'undefined' &&  this.state.cqData.searchInputName ? this.state.cqData.searchInputName : this.state.searchInputName;
    const searchProductsdevice = !window.isAgentSite ? (ts.searchProducts && ts.searchProducts.devices && ts.searchProducts.devices.list) : (ts.searchProducts && ts.searchProducts.devices);

    return (
      <div className={containerClass} onKeyDown={e => this.checkKeyboardUser(e)}>
        {ts.ribbonOverlay &&
          <ModalOverlay
            props={cq.ribbon}
            toggleModal={this.toggleRibbonOverlayModal}
          />}
        
        <a className="header-accessibility" tabindex="0" href="https://www.verizon.com/about/accessibility/overview/">
          <span>Accessibility Resource Center</span>
        </a>
        <a className="header-accessibility" tabindex="0" href="javascript:gnav.dom('#headerEnd').focus();" onKeyUp={this.handleSkipToMainContent}
        onFocus={e => this.checkKeyboardUser(9)}>
          <span>Skip to Main Content</span>
        </a>
        
        {ribbonPromoText &&
            <div className="promo-ribbon">
              <div className="clearfix">
                <div>
                  {!cq.ribbon.ribbonlink && cq.ribbon.enablemodal !== '_Yes' &&
                    <span className="color_ff">
                      {ribbonPromoText}
                    </span>
                  }
                  {cq.ribbon.ribbonlink && cq.ribbon.enablemodal !== '_Yes' &&
                    <a className="ribbonPromoExternalLink" href={cq.ribbon.ribbonlink}>
                      {ribbonPromoText}
                    </a>
                  }
                  {cq.ribbon.enablemodal === '_Yes' &&
                    <span className="pointer" onClick={this.toggleRibbonOverlayModal}>
                      {ribbonPromoText}
                      <a className="ribbonPromoInfoIcon" aria-label="Promotion.  More information tooltip. Note activating will open an overlay window" href="javascript:void(0)">
                        <svg focusable="false" focusable="false" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
                          <g fill="none" fill-rule="evenodd">
                            <path stroke="#FFF" d="M7 .938A6.062 6.062 0 0 0 .937 7 6.062 6.062 0 0 0 7 13.063 6.062 6.062 0 0 0 13.063 7 6.062 6.062 0 0 0 7 .937z" />
                            <path fill="#FFF" d="M6.211 11.375V6.228h1.576v5.147zM7.789 4.503H6.21V3.09h1.576v1.414z" />
                          </g>
                        </svg>
                      </a>
                    </span>
                  }
                </div>
              </div>
            </div>
          }

          {
            gnav.isAgentSite && !isMobile && cq.agentAuthText &&
            <div className="agt-navbar-top">
              <div className="clearfix">
                <div className="container">
                  <div className="floatLeft agt-topnav">
                    <p className="color_4B">
                      {cq.agentAuthText}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }        
        <nav role="navigation" className={gnav.agentSiteId !== 800003 ? `container-gnav clear-float ${hamburgerActive} ${searchActive} ${hasRibbonPromoClass}${hasAgentBannerClass}`: `container-gnav clear-float ${hamburgerActive} ${searchActive} ${hasRibbonPromoClass}${hasAgentBannerClass} cellular-headerlogo-border`} style={`z-index:${navZIndex}`}>
          
          
          
           <div className="sub-container-gnav">
           <div className={gnav.agentSiteId !== 800003 ? "logo-container" : "logo-container cellularlogo-container" }>
              {
                gnav.agentSiteId && <a aria-label={`${cq.agentSiteName} homepage`} href={cq.homePageURL ? cq.homePageURL : "/"} onFocus={this.handleLogoFocus}>
                   <img className={gnav.agentSiteId !== 800003 ? "" : "cellularlogo-image"}  src={logoUrl} alt="" />
                </a>
              }
              {
                !gnav.agentSiteId && <a className="logoWhiteBg" aria-label="Verizon Wireless homepage" href={cq.homePageURL ? cq.homePageURL : gnav.cnst.APP_DOMAIN} onFocus={this.handleLogoFocus}>

                </a>
              }
            </div> 
            <div className={`nav-wrapper clear-float ${searchActive} ${hideCheckoutNav}`}>
              <div className={`nav-rule`} />
              <div className="menu-container">
              {variation!==3 && !ts.isPrivacy && 
                <div className="main-menu">
                  <ul class="global-nav-list">
                  	<li className="close-hamburger">
                      <button id="hamburgerMenuCloseBtn" onClick={this.handleOverlayClose} tabindex="0" aria-label="close navigation menu">
                        <svg focusable="false" className="close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                          <path fill="#000" fill-rule="nonzero" d="M17.272 1.522l-.794-.794L9 8.205 1.522.728l-.794.794L8.205 9 .728 16.478l.794.794L9 9.795l7.478 7.477.794-.794L9.795 9z" />
                        </svg>
                      </button>
                    </li>
                    {cq.gnavTop && cq.gnavTop.map((data, index) => {
                      if (index === 0) {
                        return (<li><a href={data.URL} className={ts.isBusiness ? `colorLightDark` : `color_00`} target={data.target} onClick={this.handleEraseMybizcookie}>{data.label}</a></li>);
                      }
                      if (index === 1) {
                        return (<li><a className="colorLightDark" target={data.target} href={ts.myVerizonUrl ? ts.myVerizonUrl : data.URL}>{data.label}</a></li>);
                      }
                      return (<li><a href={data.URL} className={ts.isBusiness ? `color_00` : `colorLightDark`}>{data.label}</a></li>);
                    })}
                    
                  </ul>
                </div>
              }
              {!variation &&   !ts.isPrivacy &&
                <div className={`links-menu`} >
                  <ul className={`${gnav.isAgentSite ? "global-nav-list menubar agentMenu" : "global-nav-list menubar"}`} onMouseLeave={this.closeDeskSubMenu.bind(this)}>
                    {cq.gNavMenu && cq.gNavMenu.map((data, index) => {
                    	if(!this.checkConditions(data)){return}
                      return (
                        <li id={"secondLevel"+index} className={(!(data.conditions) || data.conditions.role && data.conditions.role.indexOf(userRole) !== -1 ? "" : "displayNone", !isMobile && ("secondLevel"+index === ts.selectedParentLevel) ? "activeBorder" : "")}   
                        	 onMouseenter={this.handleMouseover.bind(this, index)} 
                        	 onMouseLeave={this.handleMouseLeave.bind(this, index)}>
                         	{/* main nav links - some complicated interaction in these links/buttons for a11y */}
                         	<span class="gn-relative">
                         		<button aria-expanded={`${index === linkToRender}`}  onclick={this.handleA11yMainNavLinks.bind(this, index)} className={`a11y-sr-only ${index === linkToRender && "hover-active"}`} >{data.label} list</button>
                         		<span aria-hidden="true" className={`main-link`} href={userRole === "mobilesecure" && data.memberurl ? data.memberurl : data.URL} target={data.target} onFocus={this.handleMouseover.bind(this, index)} onClick={this.handlePreventDefaultMobile.bind(this, data)} onKeyup={this.handleMenubarKeyup}>{data.label}</span> 
                         	</span>
                          {<div className={`global-nav-lower position-submenu clear-float ${(index === linkToRender && (data.subMenu.length > 0 || data.subBottomMenu.length > 0)) ? "displayBlock" : ""}`} >
                            <div className="sub-global-nav-lower">
                              {/* index === linkToRender && menubarWrapper */}
                              {index === linkToRender && data.heading && <div className="lower-left">
                                <h2 className="gnav-dd-heading">{data.heading}</h2>
                                <a className="gnav-dd-anchor" href={data.headingCTALink} target={data.target}>
                                  {data.headingCTA}
                                  <span class="gnav-right-arrow"></span>
                                </a>
                              </div>
                              }
                              <div className={`lower-right ${ts.shiftMe ? "shiftMe" : ""} ${index === linkToRender ? "displayBlock" : ""}`}>
                                {/* For mobile we need Back button   */}
                                <div role="button" tabindex="0" className="mobile-back" onClick={this.handleSubmenuBack}><span className="back-carat" />Back</div>
                                {data.subMenu.length > 0 &&
                                  <ul className="gnav-submenu-list">
                                  	{data.subMenuHeading &&
		                                	<li className="bold">{data.subMenuHeading}</li>
		                                }
                                    {data.subMenu.map((value, ind) => {
                                    	if(!this.checkConditions(value)){return}
                                      return (<li className={ !isMobile && ("secondLevel"+index) === ts.selectedParentLevel && ("thirdLevel"+index+""+ind) === ts.selectedElementId ? "activeBorder" : ""}><a onClick={this.handleActiveState} className="" id={"thirdLevel"+index+""+ind} href={value.URL} target={value.target} >{value.label}</a></li>);
                                    })}
                                  </ul>
                                }                               
                                {data.subBottomMenu && data.subBottomMenu.length > 0 &&
                                  <ul className="gnav-submenu-list">
                                  	{data.subBottomMenuHeading &&
		                                	<li className="bold">{data.subBottomMenuHeading}</li>
		                                }
                                    {data.subBottomMenu && data.subBottomMenu.map((value, ind) => {
                                    	if(!this.checkConditions(value)){return}
                                    	return (<li className={!isMobile && ("secondLevel"+index) === ts.selectedParentLevel && ("fourthLevel"+index+""+ind) === ts.selectedElementId ? "activeBorder" : ""}><a onClick={this.handleActiveState} id={"fourthLevel"+index+""+ind} target={value.target} href={value.URL}>{value.label}</a></li>);
                                    })}
                                  </ul>
                                }
                              </div>
                            </div>
                          </div>}
                        </li>
                      );
                    })}
                    {isMobile && menuNode && isLoggedIn &&
                        <li className="account-username-icon floatRightMobile hideAccountBlock">
                          <a tabindex="0" role="button" aria-label="open account overlay" onClick={this.handleMobileRender.bind(this, cq.gNavMenu.length + 1)}>{menuHeading}</a>
                          <div className={`global-nav-lower position-submenu clear-float ${((cq.gNavMenu.length + 1) === linkToRender && (menuNode.subMenu.length > 0)) ? "displayBlock" : ""}`}  >
                            <div className="sub-global-nav-lower">
                            <div className={`lower-right ${ts.shiftMe ? "shiftMe" : ""} ${(cq.gNavMenu.length + 1) === linkToRender ? "displayBlock" : ""}`}>
                              {/* For mobile we need Back button   */}
                              <div role="button" tabindex="0" className="mobile-back" onClick={this.handleSubmenuBack}><span className="back-carat" />Back
                              {acctNum &&  // account number display removed pending hash requirements  <br/><span className="bold"># {acctNum}</span>
				                      	<div className="acct-num">{ts.displayRole}
				                      		
				                      	</div>
				                      }
                              </div>
                              <ul className="gnav-submenu-list">
                              	{ts.myVerizonUrl &&  !ts.isPrivacy &&
					                      	<li><a href={ts.myVerizonUrl} onClick={this.handleSignOutUrl} onBlur={this.handleAccountMenuEnd}>My Fios account</a></li>
					                      }
                                {menuNode.subMenu.map((dropdownValue) => {
                                	if(!this.checkConditions(dropdownValue)){return}

                                  const url = isMobile && dropdownValue.mobileURL ? dropdownValue.mobileURL : dropdownValue.URL
                                  return (
                                    <li><a href={url} onClick={this.handleSignOutUrl} onBlur={this.handleAccountMenuEnd}>{dropdownValue.label}</a></li>
                                  )
                                })}
                              </ul>
                             </div>
                             </div>
                            </div>
                        </li>
                    }
                  </ul>
                </div>
                }
              </div>

              <div className="nav-tools-container">
                <ul className="global-nav-list search-cart">
                {cq.storeLocator && cq.storeLocator.label && !variation && 
                  <li className="stores-option">
                  <a  href={cq.storeLocator.URL}>{cq.storeLocator.label}</a>
                  </li>
                }
                {!variation && cq.search.label &&
                  <li className="search-container">

                    <form action={searchTargetURL} onSubmit={this.searchOnSubmit}>
                    
                    	<input name="ES" type="hidden" value={ts.ESsearchVal} />
											<input name="src" type="hidden" value="mobile" />
                      <input className="search" 
                      	tabindex={ts.searchResultOverlay ? 0 : -1}
                      	aria-hidden={!ts.searchResultOverlay}
                      	aria-label="enter search term"
                        type="text"
                        name={searchInputName}
                        id="navPhoneSearch"
                        maxlength="100"
                        placeholder={cq.search && cq.search.label ? cq.search.label : "Search"}
                        autocomplete="off"
                        onClick={this.handleSearchFocus}
                        onKeyUp={this.handleNewSearch} />


                    <a className="search-icon" onClick={this.handleSearchFocus} tabindex={ts.searchResultOverlay ? -1 : 0} href="#" aria-label="search verizon wireless">
                        <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                          <path fill="#000" fill-rule="nonzero" d="M17.896 17.101l-5.995-5.994A6.721 6.721 0 0 0 13.5 6.75a6.75 6.75 0 1 0-6.75 6.75c1.661 0 3.18-.603 4.356-1.598l5.995 5.995.795-.796zM6.75 12.375A5.63 5.63 0 0 1 1.125 6.75 5.63 5.63 0 0 1 6.75 1.125a5.63 5.63 0 0 1 5.625 5.625 5.63 5.63 0 0 1-5.625 5.625z" />
                        </svg>
                      </a>

                    </form>

                  </li>
                 }{!variation &&  cq.search.label &&
                  <li className="close-search">
                    <span className={`search-loader ${ts.isFetchingSearch ? "loader-active" : ""}`}>Loading</span>
                    <button id="gnavCloseSearch" aria-label="close search results" className="" onClick={this.handleOverlayClose}>
                      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                        <path fill="#000" fill-rule="nonzero" d="M17.272 1.522l-.794-.794L9 8.205 1.522.728l-.794.794L8.205 9 .728 16.478l.794.794L9 9.795l7.478 7.477.794-.794L9.795 9z" />
                      </svg>
                    </button>
                  </li>
                 }
                 
                 {!variation &&  !ts.isPrivacy && cq.wishList && cq.wishList.label &&
                  <li className="floatRightMobile wishList">
                    <a href={cq.wishList.URL} aria-label={`${cq.wishList.label}, you have ${wishListCount ? wishListCount : 0} item${wishListCount != 1 ? 's' : ''}`}>
                    	<svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 15.8" width="16px" height="16px" style="overflow:visible">
                    	<path d="M13.16,2.2a4,4,0,0,1,4,4,4.32,4.32,0,0,1-1.5,3l-6.4,6.1L2.56,9a3.51,3.51,0,0,1-1.2-2.8,4,4,0,0,1,4-4,5.42,5.42,0,0,1,3.8,2.4l.1.1.1-.1c1-1.3,2.6-2.4,3.8-2.4m0-1.1A5.08,5.08,0,0,0,9.26,3a5.08,5.08,0,0,0-3.9-1.9A5.12,5.12,0,0,0,.26,6.2a4.75,4.75,0,0,0,1.5,3.6l7.5,7.1,7.2-6.9a5.35,5.35,0,0,0,1.8-3.8,5.12,5.12,0,0,0-5.1-5.1Z" transform="translate(-0.26 -1.1)"/>
                          {wishListCount &&
                            <circle cx="5.5" cy="5.5" r="5.5" fill="#D0021B" transform="translate(9)" id="wishListCircle"/>
                          }
                          {wishListCount &&
                            <text fill="#FFF" font-family="arial" font-size="10" transform="translate(9)">
                              <tspan x={`${wishListCount > 9 ? "2.5" : "3"}`} y="9" id="wishListCount">{wishListCount}</tspan>
                            </text>}
                      </svg>
                    </a>
                  </li>
                 }
                 
                 {/*
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 15.8"><path d="M13.16,2.2a4,4,0,0,1,4,4,4.32,4.32,0,0,1-1.5,3l-6.4,6.1L2.56,9a3.51,3.51,0,0,1-1.2-2.8,4,4,0,0,1,4-4,5.42,5.42,0,0,1,3.8,2.4l.1.1.1-.1c1-1.3,2.6-2.4,3.8-2.4m0-1.1A5.08,5.08,0,0,0,9.26,3a5.08,5.08,0,0,0-3.9-1.9A5.12,5.12,0,0,0,.26,6.2a4.75,4.75,0,0,0,1.5,3.6l7.5,7.1,7.2-6.9a5.35,5.35,0,0,0,1.8-3.8,5.12,5.12,0,0,0-5.1-5.1Z" transform="translate(-0.26 -1.1)"/></svg>
                 */}
                 
                 
                 {!variation && window.siteId !== 800003 && location.href.indexOf("csprod.verizon")<0 && cartURL && cq.cart.label &&
                 <li className="floatRightMobile">
                  <a className={`cart-icon ${hideCartIconInOC}`} aria-label={fullCartLabel}	href={cartURL}>
                      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" style="overflow:visible">
                        <g fill="none" fill-rule="evenodd">
                          <path fill="#020303" d="M11.354 13.875H.838v-8.99h1.845v1.45h.839v-1.45h5.15v1.45h.837v-1.45h1.845v8.99zM3.522 3.285c0-.794.641-1.439 1.433-1.447h2.283c.791.008 1.433.653 1.433 1.446v.763h-5.15v-.763zm7.14.762H9.51v-.763A2.285 2.285 0 0 0 7.24 1H4.954a2.285 2.285 0 0 0-2.272 2.284v.763H0v10.667h12.192V4.047h-1.53z" />
                          {cartCount &&
                            <circle cx="5.5" cy="5.5" r="5.5" fill="#D0021B" transform="translate(7)" id="navCartCircle"/>
                          }
                          {cartCount &&
                            <text fill="#FFF" font-family="arial" font-size="8" transform="translate(7)">
                              <tspan x={`${cartCount > 9 ? "2.5" : "3"}`} y="9" id="navCartCount">{cartCount}</tspan>
                            </text>}
                        </g>
                      </svg>
                    </a>
                  </li>
                 }{!variation && !menuNode && 
                 	<li className="floatMobileLeft"><a className="bold" href={cq.login && cq.login.URL}>{cq.login && cq.login.label}</a></li>
                 }{!ts.isPrivacy && variation !== 3 &&
                  <li className="menu-icon">
                    <div id="hamburgerMenuOpenBtn" className="" onClick={this.handleHamburgerMenu} onKeyPress={this.handleKeyUpHamburgerMenu} tabindex="0" role="button" aria-label={`open navigation menu. ${notifications ? " you have new notifications" : ""}`}>
                      <div>
                      	{notifications && <span className="notificon"/>}
                      </div>
                    </div> 
                  </li>
                  }
                </ul>
                <ul className={`global-nav-list ${variation ? "search-cart" : "language-account"}`}>
                  {menuNode && !variation && (!isLoggedIn || ts.isPrivacy) &&
                    <li className={`account-username-icon floatRightMobile ${ts.isPrivacy && "privacy"}`}>
                    	<span id="accountMenuOpenBtn" tabindex="0" role="button" aria-label={`open ${menuHeading} menu overlay`} onClick={this.handleAccountDropdownToggle}>{menuHeading}</span>
                    </li>
                  }
                  { (!gnav.cnst.DOMAIN || /verizon/.test(gnav.cnst.DOMAIN)) && !/[2-3]/.test(variation) && cq.gnavTopRight.language && cq.gnavTopRight.language.label &&
                  	<li className={`language-selector ${ts.accountDropdown ? "dropdown-active" : ""} ${ variation===1 ? "variation-pad" : ""}`}>

	                  	<a className="langLink" data-href="//espanol.verizon.com" href="https://espanol.verizon.com/" data-lang="es" mporgnav="" onClick={this.handleLanguageToggle}>Espa&ntilde;ol</a>

                        <a className="stores-option-mobile" href={cq.storeLocator ? cq.storeLocator.URL : ""}>{cq.storeLocator ? cq.storeLocator.label : ""}</a>
	                  </li>
                  }
                  {menuNode && !variation &&
                    <li
                      className={`account-container ${ts.accountDropdown ? "dropdown-active" : ""}`}
                      onMouseenter={this.handleAccountDropdownOpen}
                      onMouseLeave={this.handleAccountDropdownClose}>
                      <a href="javascript:void(0)"
                        id="gnavAccountMenu"
                        className={`${isLoggedIn ? "loggedin" : ""}`}
                        aria-label={`${menuHeading} ${ts.accountDropdown ? "expanded." : "collapsed."}${notifications ? " You have new notifications." : ""}`}
                        onClick={this.handleAccountDropdownToggle}
                      >
	                      <span>{menuHeading}
													{notifications && <span className="notificon"/>}
	                      </span>
                      </a>
                      {acctNum &&  // account number display removed pending hash requirements <br/><span className="bold"># {acctNum}</span>	
                      	<div className="acct-num">{ts.displayRole}
                      		
                      	</div>
                      }
                      {ts.accountDropdown && (!isLoggedIn || ts.isPrivacy) &&
                        <li class="close-hamburger">
                          <button id="accountMenuCloseBtn" onClick={this.handleOverlayClose} aria-label={`close ${menuHeading} menu overlay`}>
                            <svg focusable="false" className="close-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                              <path fill="#000" fill-rule="nonzero" d="M17.272 1.522l-.794-.794L9 8.205 1.522.728l-.794.794L8.205 9 .728 16.478l.794.794L9 9.795l7.478 7.477.794-.794L9.795 9z" />
                            </svg>
                          </button>
                        </li>
                      }
                      <ul>
                      	{ts.myVerizonUrl && !ts.isPrivacy &&
	                      	<li><a href={ts.myVerizonUrl} onClick={this.handleSignOutUrl} onBlur={this.handleAccountMenuEnd}>My Fios account</a></li>
	                      }
                        {menuNode.subMenu.map((dropdownValue) => {
                        	if(!this.checkConditions(dropdownValue)){return}
                          const url = isMobile && dropdownValue.mobileURL ? dropdownValue.mobileURL : dropdownValue.URL
                          return (
                            <li><a href={url} onClick={this.handleSignOutUrl} onBlur={this.handleAccountMenuEnd}>{dropdownValue.label}</a></li>
                          )
                        })}
                      </ul>
                    </li>
                  }
                </ul>
              </div>
            </div>
            {gnav.isAgentSite && !isMobile && cq.agentAuthText && cq.agentAuthLogo &&
              <div className={this.state.searchResultOverlay ? 'agt-static-logo-hidedisplay' : 'agt-static-logo'}>
                <img src={cq.agentAuthLogo} className="img-responsive" title="authorized retailer logo" alt="authorized retailer logo" />
                <span className="agt-static-text">authorized retailer</span>
              </div>
            }

            {(ts.searchResultOverlay || ts.hamburgerMenu) && <div className={`global-nav-lower position-submenu clear-float search-result ${searchActive} ${hamburgerActive} ${noResultsYet}`} aria-hidden={ariaHideSearchResults}>
              <div className="sub-global-nav-lower">
                <div className="lower-left productList">
                  {ts.searchString !== null && ts.searchProducts && !ts.searchError &&
                    <ul className="gnav-submenu-productlist clearfix">
                    {searchProductsdevice && searchProductsdevice.map((result) =>{
                    	result.price = parseFloat(result.price) > 0 ? parseFloat(result.price) : null
                      return(
                        <li role="link" {...gnav.accessibleClickEnter(() => {this.handleClickSearchListItem(result.url)})} >
                          <div className="productDescription">
                          <span className="productName" dangerouslySetInnerHTML={!window.isAgentSite ? { __html: result.name } : { __html: result.name.replace("&amp;", "&") }}></span><br/>
                            {result.price && <span className="startAt">Starts at</span>}<br/>
                            {result.price && <span className="productPrice">{`$${result.price.toFixed(2)}${result.priceSuffix ? result.priceSuffix : ''}`}</span>}<br/>
                            {result.price && <span className="productPriceType">{`${result.priceLabel ? result.priceLabel : ''}`}</span>}
                          </div>
                          <div className="productImage">
                          <img alt={result.img_alt} src={!window.isAgentSite ? result.img_url : result.imgUrl }/>
                          </div>
                        </li>
                        )
                      }
                    )}
                   {ts.searchProducts.accessories && ts.searchProducts.accessories.list && ts.searchProducts.accessories.list.map((result) =>{
                      return(
                        <li role="link" {...gnav.accessibleClickEnter(() => {this.handleClickSearchListItem(result.url)})} >
                          <div className="productDescription">
                            <span className="productName" dangerouslySetInnerHTML={{ __html: result.name }}></span><br/>
                            {result.price && <span className="productPrice">{`$${parseFloat(result.price).toFixed(2)}${result.priceSuffix ? result.priceSuffix : ''}`}</span>}<br/>
                            <span className="productPriceType">{`${result.priceLabel ? result.priceLabel : ''}`}</span>
                          </div>
                          <div className="productImage">
                            <img alt={result.img_alt} src={result.img_url}/>
                          </div>
                        </li>
                        )
                      }
                    )}
                   {ts.searchProducts.categories && ts.searchProducts.categories.list && ts.searchProducts.categories.list.map((result) =>{
                      return(
                        <li role="link" {...gnav.accessibleClickEnter(() => {this.handleClickSearchListItem(result.url)})} >
                          <div className="productDescription">
                            <span className="categoryName" dangerouslySetInnerHTML={{ __html: result.name }}></span><br/>
                          </div>
                          <div className="productImage">
                            <img alt={result.img_alt} src={result.img_url}/>
                          </div>
                        </li>
                        )
                      }
                    )}
                  </ul>}
                </div>
                <div className="lower-right">
                {ts.searchString === null && 
                  <ul className="floatLeft  gnav-submenu-list">
                    {ts.searchPopular && ts.searchPopular.summary && ts.searchPopular.summary.SuggestedTerms && ts.searchPopular.summary.SuggestedTerms.length > 0 && shopDealsHeading &&
                      <li><span className="bold" dangerouslySetInnerHTML={{ __html: shopDealsHeading }} /></li>
                    }
                    {ts.searchString === null && ts.searchPopular && ts.searchPopular.summary && ts.searchPopular.summary.SuggestedTerms && ts.searchPopular.summary.SuggestedTerms.map((result) => {
                      return (
                        <li><a className="" href={result.URL}>{result.label}</a></li>
                      );
                    })}
                  </ul>
                }
                {ts.searchString === null && 
                  	<ul className="floatLeft  gnav-submenu-list gnav-column2">
                    {ts.searchString === null && ts.searchPopular && ts.searchPopular.summary && ts.searchPopular.summary.RelatedTerms && ts.searchPopular.summary.RelatedTerms.length > 0 &&
                      <li><span className="bold" dangerouslySetInnerHTML={{ __html: searchResultsHeading }} /></li>
                    }
                    {ts.searchString === null && ts.searchPopular && ts.searchPopular.summary && ts.searchPopular.summary.RelatedTerms && ts.searchPopular.summary.RelatedTerms.map((result) => {
                      return (
                        <li><a className="" href={result.URL}>{result.label}</a></li>
                      );
                    })}
                  </ul>
                }   
                {ts.searchString != null && 
                	<ul className="floatLeft  gnav-submenu-list gnav-results">
                		{!ts.searchError && ts.searchResults &&  ts.searchResults.typeAheadSummary &&  ts.searchResults.typeAheadSummary.TypeAheadSuggestedTerms && ts.searchResults.typeAheadSummary.TypeAheadSuggestedTerms.map((result) => {

                      return (
                        <li><a className="" href={result.href} onMouseEnter={()=>{this.handleSearchTermHover(result.name)}} >{result.name}</a></li>
                      );
                    })}
                	</ul>
                }    
                    
                  
                  {ts.searchString !== null && ts.searchProducts && !ts.searchError && <div className="gnav-right-menu floatLeft">
                    {ts.searchProducts.support && ts.searchProducts.support.list && <div className="gnav-right-menu-item">
                      <span className="listHeading">{`${ts.searchProducts.support.label}:`}</span>
                      <ul>
                      { ts.searchProducts.support.list.map((result) => {
                        return (
                          <li className="listDetail"><a className="" href={result.url} dangerouslySetInnerHTML={{ __html: result.name }} /></li>
                        );
                        })
                      }
                      </ul>
                    </div>}
                    {ts.searchProducts.myverizon && ts.searchProducts.myverizon.list && <div className="gnav-right-menu-item">
                      <span className="listHeading">{`${ts.searchProducts.myverizon.label}:`}</span>
                      <ul>
                      { ts.searchProducts.myverizon.list.map((result) => {
                        return (
                          <li className="listDetail"><a className="" href={result.url} dangerouslySetInnerHTML={{ __html: result.name }} /></li>
                        );
                        })
                      }
                      </ul>
                    </div>}
                    {ts.searchProducts.community && ts.searchProducts.community.list && <div className="gnav-right-menu-item">
                      <span className="listHeading">{`${ts.searchProducts.community.label}:`}</span>
                      <ul>
                      {ts.searchProducts.community.list.map((result) => {
                        return (
                          <li className="listDetail"><a className="" href={result.url} dangerouslySetInnerHTML={{ __html: result.name }} /></li>
                        );
                        })
                      }
                      </ul>
                    </div>}
                    {ts.searchProducts.business && ts.searchProducts.business.list && <div className="gnav-right-menu-item">
                      <span className="listHeading">{`${ts.searchProducts.business.label}:`}</span>
                      <ul>
                      { ts.searchProducts.business.list.map((result) => {
                        return (
                          <li className="listDetail"><a className="" href={result.url} dangerouslySetInnerHTML={{ __html: result.name }} /></li>
                        );
                        })
                      }
                      </ul>
                    </div>}
                  </div>}
                  <span tabindex="0" onFocus={this.handleSearchMenuEnd} />
                </div>
              </div>
            </div>}
          </div>
        </nav>
       
        {/* <div id="headerEnd" tabindex="-1"></div> */}
        {gnav.agentSiteId && isMobile && cq.agentAuthText && cq.agentAuthLogo && 
        <div class="authorizedSection">
          <div class="authorDecription">
            <p aria-label={cq.agentAuthText} class="authorizedLogoTxt">{cq.agentAuthText}</p>
          </div>
          <div class="authorLogo">
            <img aria-label="authorized retailer Logo" src={cq.agentAuthLogo} />
            <span aria-hidden="true">authorized retailer</span>
          </div>
        </div>}
        
        {/* BEGIN - new promo message bar */}
        {!gnavOptions.hidePromoRibbon && cq['messaging-bar'] && cq['messaging-bar'].messaging.length &&
	        <div id="vzw-gn-msg-bar" className="promo-ribbon-2">
	        	<div class="clearfix">
	        		{cq['messaging-bar'].messaging.map((msg,i) => {
						  		const view = document.body.offsetWidth<768 ? 'mobile' : 'desktop',
						  					bar = cq['messaging-bar'],
						  					divider = (i == 0 && bar.messaging.length > 1) || (view == 'desktop' && bar.showDivider && i < bar.messaging.length-1) ? 'msg-divider' : '';
	                if(msg[view]){
	                	return (
			              	<div className={divider}>
			              		<span dangerouslySetInnerHTML={{ __html: msg[view].text}} />&nbsp;&nbsp;&nbsp;
			              		{msg.tooltip && !ts.tooltipRendered &&
			              			<span className="global-a11y-tooltip">
			              				{msg.tooltip.title &&
			              					<b>{msg.tooltip.title}<br /><br /></b>
			              				}
			              				{msg.tooltip.text}
			              			</span>
			              		}
			              	</div>
		                )
	                }
                })
              }
	        	</div>
	        </div>
	      } 
	      {/* END - new promo message bar */}
	       <div className={`sticky-spacer ${hasRibbonPromoClass}`} id="headerEnd" tabindex="-1" />
      </div>
    );
  }
}

// lazy-load legacy chat js
var vzgn_chatConf={
	chatDisable:false,
	load:function(){
		try{
			var lzLoadEl = document.createElement('script'); 
			lzLoadEl.type = 'text/javascript'; 
			lzLoadEl.async = true;
			lzLoadEl.src = gnav.cnst.APP_DOMAIN + "/VZWChat/VZ_Chat_GN.js"; 
			var scriptEl = document.getElementsByTagName('script')[0]; 
			scriptEl.parentNode.insertBefore(lzLoadEl, scriptEl);
		}catch(e){}
	}   
};

function loadGnav(){
	if(!gnavOptions.hideGnav){
		//console.log('---loadGnav');
		var noGnavPaths = [];
		if((window.bau984 || window.legacyMobileOmninav) && !document.getElementById('vzw-gn') && !gnav.find(noGnavPaths,location.href)){
			//console.log('---createElement(div)');
			var legacy984 = gnavOptions.legacy984 ? 'legacy984' : '',
					sticky = gnavOptions.sticky ? 'sticky' : '',
					d1=document.createElement('div');
			d1.id='vzw-gn';
			d1.className=`${sticky} ${legacy984}`;
			document.body.insertBefore(d1, document.body.firstElementChild);
			
			// lazy-load legacy chat js
			if(!(vzgn_chatConf.chatDisable || window.gnlin_chatDisable)){        
				vzgn_chatConf.load(); 
			}
		};
	
		render(<GlobalNav ref={(vzwgnav) => {window.vzwgnav = vzwgnav}} />, document.getElementById("vzw-gn"));
		document.getElementById("vzw-gn").style.display = 'block';
		// below to assist with a11y by adding role=main attribute to main div if not already present there
		function dq(x){return document.querySelector(x)};
		dq('#vzw-gn').setAttribute('role','banner')
		if(!dq('[role=main]')){
			if(dq('#app')){dq('#app').setAttribute('role','main')}
			else if(dq('#content')){dq('#content').setAttribute('role','main')}
			else if(dq('#pageWrapper')){dq('#pageWrapper').setAttribute('role','main')}
			else if(dq('#vzw-gn').nextElementSibling && dq('#vzw-gn').nextElementSibling.tagName==='DIV'){dq('#vzw-gn').nextElementSibling.setAttribute('role','main')}
		}
	}else{
		document.querySelector('#vzw-gn').style.display = 'none';
	}
	document.body.onunload=function(){
		if(!window.gnavSetNav){
			gnav.eraseCookie("selectedParentLevel");
	    gnav.eraseCookie("selectedElementId");
		}
	}
}
if(!document.body && document.addEventListener){
	//console.log('---got no body');
	document.addEventListener("DOMContentLoaded", loadGnav, false);
}else if(document.body){
	//console.log('---got body');
	loadGnav();
}

/* This method to close search curtain if user clicks outside of search curtain DOPMO-35348 */
window.vzwgnav.checkCloseSearch = function(clienty){
	var gnavWithSearch = document.querySelector('.container-gnav.search-active')
	if(gnavWithSearch){
		var curtainHeight = 535;
		if(gnavWithSearch.querySelector('.global-nav-lower.no-results-yet')){
			curtainHeight = gnavWithSearch.clientHeight
		}
		if(document.querySelector('#vzw-gn .promo-ribbon')){
			curtainHeight += document.querySelector('#vzw-gn .promo-ribbon').clientHeight
		}
		if(clienty > curtainHeight){
			document.getElementById('gnavCloseSearch').click()
		}
	}
}
window.addEventListener('touchstart', function(e) {
    //console.log('user touched screen',e.touches)
    if(window.vzwgnav.state){
    	window.vzwgnav.state.touchScreen = true;
    }
});
window.addEventListener('click', function(e) {
    window.vzwgnav.checkCloseSearch(e.clientY)
});

gnav.testIndicatorIndex = 0
gnav.testIndicatorInterval = setInterval(function(){
	gnav.testIndicatorIndex ++;
	if(window.runningExperiment && document.getElementById('visual-cue')){
		var indicator = document.getElementById('visual-cue'),
				divs = indicator.children
		indicator.style.display = 'block';
		window.runningExperiment.forEach(function(item,index){
			if(/optimization/.test(item.toLowerCase())){
				divs[0].setAttribute('class', 'visual-cue-show')
			}else if(/merchandising/.test(item.toLowerCase())){
				divs[1].setAttribute('class', 'visual-cue-show')
			}else if(/personalization/.test(item.toLowerCase())){
				divs[2].setAttribute('class', 'visual-cue-show')
			}
		})
	}
	if(gnav.testIndicatorIndex>29){
		clearInterval(gnav.testIndicatorInterval)
	}
},1000)
