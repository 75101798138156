var preact = window.preact;
var h = preact.h;
var Component = preact.Component;
var render = preact.render;
// Signin Modal Overlay
class ModalOverlay extends Component {
	constructor() {
		super();
	}
	componentDidMount() {
		gnav.dom("#ribbonOverlayClose").focus();
	}
	handleTrapTabKey() {
		gnav.dom("#ribbonOverlayClose").focus();
	}
	handleEscKey(e) {
		if(e.keyCode == '27' || e.keyCode == '13' || e.keyCode == '32') {
			this.props.toggleModal(e);
		}
	}
	render({ props, toggleModal, closeButton, className }) {	
		const { modalHeading, modalSubheading } = props;
		return (
			<div id="gnavModal" className="gnav-overlay">
				<div className={`overlay-modal  ${className}`}>
					<div className="overlay-wrapper">
						<button id="ribbonOverlayClose" className="modal-close" onClick={toggleModal} onKeyDown={(e)=> this.handleEscKey(e)} aria-label="Close overlay">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<g fill="none" fill-rule="evenodd" stroke="#000" stroke-linecap="square">
									<path d="M.665.833l18.82 18.821M.665 19.654L19.485.834" />
								</g>
							</svg>
						</button>
						<h2 className="heading">{modalHeading}</h2>
						<div className="sub-heading">
							<p>{modalSubheading}</p>
						</div>
						{closeButton &&
							<div>
								<button class="button-primary" aria-label="close overlay" onClick={toggleModal} onKeyDown={(e)=> this.handleEscKey(e)}>Close</button>
								<span>&nbsp;</span>
							</div>
						}
						{false && <button onFocus={this.handleTrapTabKey} style="border:0;background:none;position:absolute;"></button>}
					</div>
				</div>
			</div>
		);
	}
}
const Facebook = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
			<path fill="#FFF" fill-rule="nonzero" d="M17.926 0H1.074C.496 0 0 .496 0 1.074v16.852C0 18.504.496 19 1.074 19h9.087v-7.352H7.683V8.757h2.478V6.609c0-2.479 1.487-3.8 3.635-3.8 1.074 0 1.982.082 2.23.082v2.561H14.54c-1.156 0-1.404.578-1.404 1.405v1.817h2.808l-.33 2.891h-2.478v7.352h4.791c.578 0 1.074-.495 1.074-1.074V.991C19 .496 18.504 0 17.926 0z" />
		</svg>
	)
}
const GooglePlus = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="17" viewBox="0 0 26 17">
			<path fill="#FFF" fill-rule="nonzero" d="M8.193 6.955v3.348h4.424c-.655 2.146-1.72 3.349-4.424 3.349-2.622 0-4.752-2.233-4.752-5.066 0-2.833 2.048-5.066 4.752-5.066 1.393 0 2.294.515 3.113 1.202.656-.687.574-.773 2.294-2.49C12.125.86 10.241 0 8.11 0 3.606 0 0 3.778 0 8.5 0 13.222 3.605 17 8.11 17c6.719 0 8.358-6.096 7.784-10.217-1.475.172-7.701.172-7.701.172zm14.913-.061V4H20.98v2.894H18V9.02h2.979V12h2.127V9.021H26V6.894h-2.894z" />
		</svg>
	)
}
const Twitter = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
			<path fill="#FFF" fill-rule="nonzero" d="M6.134 16C13.782 16 17.9 9.814 17.9 4.454v-.495c.84-.578 1.513-1.32 2.101-2.062-.756.33-1.513.577-2.353.66A4.409 4.409 0 0 0 19.496.33c-.84.495-1.68.825-2.605.99A4.103 4.103 0 0 0 13.866 0c-2.27 0-4.118 1.814-4.118 4.041 0 .33 0 .66.084.907A11.862 11.862 0 0 1 1.345.742c-.337.578-.589 1.32-.589 2.062 0 1.402.757 2.64 1.849 3.382-.672 0-1.344-.165-1.849-.495v.082a4.015 4.015 0 0 0 3.362 3.959c-.336.082-.673.165-1.093.165-.252 0-.504 0-.756-.083.504 1.65 2.017 2.805 3.865 2.805-1.428 1.072-3.193 1.732-5.126 1.732-.336 0-.672 0-1.008-.083C1.68 15.34 3.866 16 6.134 16" />
		</svg>
	)
}