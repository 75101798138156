class GlobalFooter extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: gnav.getCookie("loggedIn"),
      isBusiness: gnav.biz,
      isService: gnav.getCookie("loggedIn") && gnavOptions.version === "service",
      //isPrivacy: gnavOptions.version === "privacy",
      variation: gnavOptions.variation
    }
    this.handleStoreLocatorFocus = this.handleStoreLocatorFocus.bind(this);
  }
  componentDidMount() {
    let _this = this


	  const JSON_CONTRACT_URL = this.state.isPrivacy
	    	? gnav.cnst.FOOTER_JSON_URL_PRIVACY
	    	: this.state.isBusiness
		      ? gnav.cnst.FOOTER_JSON_URL_BIZ
		      : this.state.isLoggedIn
		        ? this.state.isService
				      ? gnav.cnst.FOOTER_JSON_URL_SERVICE
				      	: gnav.cnst.FOOTER_JSON_URL_CUSTOMER
		        	: gnav.cnst.FOOTER_JSON_URL_PROSPECT
    // console.log('JSON_CONTRACT_URL',JSON_CONTRACT_URL)

    gnav.makeAjaxCall(JSON_CONTRACT_URL, "GET").then(function (userData) {
      _this.setState({ cqData: userData });
      // console.log('cqData',userData)
    });
  }

  handleStoreLocatorFocus(e) {
		gnav.loadGoogleScript() 
		if(!gnav.mobilecheck()){
			//gnav.scrollToBottom() DOPMO-18108
		}
	}
	
  render(props, state) {
    if (!this.state.cqData || this.state.cqData === null) {
      return null;
    }   
    let cqData = this.state.cqData,
    		variation = this.state.variation;
    return (
      <footer role="contentinfo" id="heroFooter" className="container-fluid">
        <div className="footer-container">
          <div className={`main-container clearfix ${gnav.isAgentSite ? "widthInherit": ""}`}>

          
          {variation !== 2 && 
         		<div className="footer-wrapper">
	          
	          {cqData.applications && cqData.applications.heading &&
	            <div className="hero-footer">
	              <div className="hero-wrapper">
	                <div className="hero-footer-heading">{cqData.applications && cqData.applications.heading}</div>
	                <div className="border-content">
	                  <ul className="footer-list">
	                    {cqData.applications && cqData.applications.contents.map((appName) => {
	                      return (
	                        <li><a className="" target={appName.target} href={appName.URL}>{appName.label}</a></li>
	                      );
	                    })}
	                  </ul>
	                </div>
	              </div>
	            </div>
	          }
	            <div className="hero-footer">
	              <div className="hero-wrapper top-cell">
	                <div className="hero-footer-heading">{cqData.aboutVerizon && cqData.aboutVerizon.heading}</div>
	                <div className="border-content">
	                  <ul className="footer-list">
	                    {cqData.aboutVerizon && cqData.aboutVerizon.contents.map((about) => {
	                      return (
	                        <li><a className="" target={about.target} href={about.URL}>{about.label}</a></li>
	                      );
	                    })}
	                  </ul>
	                </div>
	               </div> 
	                
	                
	                {cqData.topAccessoryBrands && <div className="hero-wrapper">
		                <div className="hero-footer-heading">{cqData.topAccessoryBrands.heading}</div>
		                <div className="border-content">
		                  <ul className="footer-list">
		                    {cqData.aboutVerizon && cqData.topAccessoryBrands.contents.map((about) => {
		                      return (
		                        <li><a className="" target={about.target} href={about.URL}>{about.label}</a></li>
		                      );
		                    })}
		                  </ul>
		                </div>
	                </div>}
	                
	                
	                
	              
	            </div>							
	            <div className="hero-footer">
	              <div className="hero-wrapper top-cell">
	                <div className="hero-footer-heading">{cqData.social && cqData.social.heading}</div>
	                <div className="border-content social-icon">
	                  {cqData.social && cqData.social.contents && cqData.social.contents.map((social) => {
	                    let a11y = social.label.split(/-(.+)/)[1];
	                    if (social.label === "fa fa-facebook-official") {
	                      return (
	                        <a href={social.URL} target={social.target}><span>{a11y}</span>
	                          <Facebook />
	                        </a>)
	                    }
	                    if (social.label === "fa fa-twitter") {
	                      return (
	                        <a href={social.URL} target={social.target}><span>{a11y}</span>
	                          <Twitter />
	                        </a>)
	                    }
	                    if (social.label === "fa fa-google-plus") {
	                      return (
	                        <a href={social.URL} target={social.target}><span>{a11y}</span>
	                          <GooglePlus />
	                        </a>)
	                    }
	                    return (
	                      <a href={social.URL} target={social.target}><span>{a11y}</span><i className={social.label} aria-hidden="true"></i></a>
	                    );
	                  })}
	                </div>
	              </div>

	              <div className="hero-wrapper">
	                <div className="hero-footer-heading">{cqData.social && cqData.social.subscribe && cqData.social.subscribe.label}</div>
	                <div className="border-content">
	                  <ul className="footer-list">
	                    {cqData.social && cqData.social.subscribe && cqData.social.subscribe.contents.map((list) => {
	                      return (
	                        <li><a className="" href={list.URL} target={list.target}>{list.label}</a></li>
	                      );
	                    })}
	                  </ul>
	                </div>
	              </div>
	            </div>
	        	<div className="hero-footer">
	            
	            
				 			{!gnav.agentSiteId && cqData.storeLocator && <div className="hero-wrapper top-cell">
	                <div className="hero-footer-heading">{cqData.storeLocator.heading}</div>
	                <div className="border-content">
	                  
	                    <input className="zip-code" id="googleSearchInput" type="text" onFocus={this.handleStoreLocatorFocus} maxLength="32" placeholder={cqData.storeLocator && cqData.storeLocator.inputPlaceHolder} aria-label={cqData.storeLocator && cqData.storeLocator.inputPlaceHolder} />
	                    <div id="googleSearchContent"></div>
	                  
	                </div>
	              </div>}
	                
	            {!gnav.agentSiteId && cqData.about && <div className="hero-wrapper">
		                <div className="hero-footer-heading">{cqData.about.heading}</div>
		                <div className="border-content">
		                  <ul className="footer-list">
		                    {cqData.aboutVerizon && cqData.about.contents.map((about) => {
		                      return (
		                        <li><a className="" target={about.target} href={about.URL}>{about.label}</a></li>
		                      );
		                    })}
		                  </ul>
		                </div>
						  	</div>}
						  
						  
						  {gnav.agentSiteId && cqData.verizonPolicies && <div className="hero-wrapper">
	                <div className="hero-footer-heading">{cqData.verizonPolicies && cqData.verizonPolicies.heading}</div>
	                <div className="border-content">
	                   <div className="border-content">
	                  <ul className="footer-list">
	                    {cqData.verizonPolicies && cqData.verizonPolicies.contents.map((appName) => {
	                      return (
	                        <li><a className="" target={appName.target} href={appName.URL}>{appName.label}</a></li>
	                      );
	                    })}
	                     <li>{!gnav.isAgentSite && <a className="logoBlackBg" href={cqData.quickLinks && cqData.quickLinks.logoCTA} target={cqData.quickLinks && cqData.quickLinks.target} aria-label="verizon wireless home page"></a>}</li>
 											 <li>{gnav.isAgentSite && <a className={gnav.agentSiteId === 800002 ? "logoBlackBg agent-retailerlogo-GW" : "logoBlackBg"} href={cqData.quickLinks && cqData.quickLinks.logoVCTA} target={cqData.quickLinks && cqData.quickLinks.target} aria-label="verizon wireless home page"></a>}</li>
	                  </ul>
	                </div>                
	                </div>
	              </div>}
	              
	            </div>
	            
	     		</div>}	
	     		

            <div className="col-xs-12 col-sm-3 col-md-3 footer-level-two m-logo-side">
              {!gnav.isAgentSite && <a className="logoBlackBg" href={cqData.quickLinks && cqData.quickLinks.logoCTA} target={cqData.quickLinks && cqData.quickLinks.target} aria-label="verizon wireless home page"></a>}
              {gnav.isAgentSite && <a href={cqData.quickLinks && cqData.quickLinks.logoCTA} target={cqData.quickLinks && cqData.quickLinks.target} aria-label="verizon wireless home page">
              <img className={gnav.agentSiteId === 800002 ? "agt-static-logo-footer agent-logo-footer-GW" : gnav.agentSiteId === 800003 ? "agt-static-logo-footer agent-logo-footer-CSOK" : "agt-static-logo-footer"} src={cqData.quickLinks.logoUrl} alt="" />
              </a>}
            </div>
            <div className="col-xs-12 col-sm-9 col-md-9 footer-level-two">
              <ul className="footer-list">
                {cqData.quickLinks && cqData.quickLinks.contents.map((list) => {
                  return (
                    <li><a target={list.target} href={list.URL}>{list.label}</a></li>
                  );
                })}
                
              </ul>
              <div id="visual-cue" className="clearfix" aria-hidden="true">
						<div /><div /><div />
					</div>
            </div>
          </div>        
         	
        </div>
      </footer>
    );
  }
}

function loadGfoot(){
	if(!gnavOptions.hideGfoot){
		var noFooterPaths = ['/cart/','/checkout/'];
		if((window.bau984 || window.legacyMobileOmninav) && !document.getElementById('vzw-gf') && !gnav.find(noFooterPaths,location.href)){
			var legacy984 = gnavOptions.legacy984 ? 'legacy984' : '',
					d2=document.createElement('div');
			d2.id='vzw-gf';
			d2.className=`${legacy984}`;
			document.body.appendChild(d2);
		}
		render(<GlobalFooter ref={(vzwgfoot) => {window.vzwgfoot = vzwgfoot}} />, document.getElementById("vzw-gf"));
	}
}
if(!document.body && document.addEventListener){
	document.addEventListener("DOMContentLoaded", loadGfoot, false);
}else if(document.body){
	loadGfoot();
}
